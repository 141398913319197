@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin tabs-panel() {
  app-tabs-panel {
    display: flex;
    flex-direction: row;
    min-height: 60px;
    padding: 0 1.5rem;
    background-color: var.$color-grey-6;
    color: var.$color-grey-3;

    .tabs-panel {
      display: flex;
      flex: 1;
      gap: 3rem;

      app-tab {
        height: 100%;

        &.hidden {
          display: none;
        }
      }
    }
  }
  .tab-actions {
    display: flex;
    align-items: center;

    app-config-icon {
      cursor: pointer;
      color: var.$brand-color-primary;
    }
  }
}
