@mixin campaign-details-form() {
  app-campaign-details-form {
    form {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    #split-panel {
      flex: 1;
      min-height: 15rem;
      overflow-x: clip;
      /*Sets the textarea to the same height as it's container*/
      .mat-mdc-form-field .mdc-text-field .mat-mdc-form-field-flex {
        height: 100%;
        .mat-mdc-form-field-infix {
          height: 100%;
          textarea {
            height: 100%;
          }
        }
      }
    }
  }
}
