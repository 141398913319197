@mixin campaign-suitability-kpi-chart-card() {
  app-campaign-suitability-kpi-chart-card {
    .pie-container {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 100%;
      height: 100%;
      max-height: 100%;
      justify-content: space-evenly;
      align-items: center;

      .pie-wrapper {
        display: flex;
        flex-direction: column;
        width: calc((100% - 2rem) / 2);
        height: 100%;

        > app-chart {
          flex: 1;

          .chart-container {
            height: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
}
