@use 'src/assets/style/_variables.scss' as var;

@mixin content-form-video() {
  app-content-form-video {
    .container {
      height: 62vh;
      min-height: 62vh;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .white-background {
        background-color: white;
        opacity: 75%;
      }

      .video-wrapper {
        min-height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .progress-wrapper {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
}
