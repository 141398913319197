@use 'src/assets/style/_variables.scss' as var;

@mixin client-overview() {
  app-client-overview {
    .client-overview-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto 1fr;
      gap: 1.25rem;
      flex: 1;

      > *:first-child app-card:first-child {
        .card {
          border-top-left-radius: 0px;
        }
      }

      app-client-overview-actions,
      app-client-overview-campaigns,
      app-client-overview-portfolios {
        grid-column: span 2;
        grid-row: span 2;
      }
    }
  }
}
