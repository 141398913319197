@use 'src/assets/style/_variables.scss' as var;

@mixin content-definition-details() {
  app-content-definition-details {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .content-def-container {
      height: 100%;
      display: grid;
      gap: 1.5rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto 1fr;
      grid-template-areas:
        'fbr-container .'
        'content-definitions hcd-mappings';

      .fbr-container {
        grid-area: fbr-container;
        display: flex;
        flex: 1;

        .card {
          flex: 1;
        }

        .fbr-content {
          display: flex;
          gap: 2rem;
          align-items: center;
          flex-direction: row;

          .fbr-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.75rem;
          }

          .mat-mdc-form-field-subscript-wrapper {
            display: none;
          }

        }
      }

      .content-definitions {
        grid-area: content-definitions;
        height: 100%;
      }

      .hcd-mappings {
        grid-area: hcd-mappings;
        height: 100%;
      }
    }
  }

  app-content-definition-dialog {
    display: flex;

    form {
      flex: 1;
      display: flex;
      flex-direction: column
    }

    #last-row {
      flex: 1;
    }

    .selected-available-container {
      padding: 1rem 0 1.25rem;

      .selected .card-header app-config-icon {
        color: var.$brand-color-primary;
      }
    }
  }

  app-hub-channel-definition-mapping-dialog {
    .row-double {
      align-items: center;
      padding: 0.5rem 0 0.5rem;

      &.header-padding {
        padding-bottom: 1.5rem;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

