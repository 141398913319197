@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin progress-card() {
  app-progress-card {
    .progress-card {
      display: flex;
      padding: 1rem 1.5rem;
      border-radius: 0.25rem;
      z-index: 1;
      position: relative;
      box-sizing: border-box;
      background-color: var.$color-white;
      color: var.$color-black-1;
      overflow: hidden;

      &.primary {
        background: var.$state-color-disable;
        color: var.$color-white;
      }

      &.header {
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        box-shadow: var.$box-shadow;

        &.h4 {
          line-height: 1.5rem;
        }

        .mat-icon {
          margin-right: 0.5rem;
        }

        > span {
          position: relative;
          top: 1px;
        }

        .mat-icon {
          font-size: 1.5rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      .h3 {
        z-index: 2;
        color: var.$color-white;
        position: relative;
        top: 1px;

        @include mixin.small {
          position: relative;
          top: 5px;
        }
      }

      .title {
        display: flex;

        mat-spinner {
          margin-left: 0.5rem;
        }
      }

      .number-container {
        z-index: 2;
        position: absolute;
        right: 1rem;
        top: 1rem;
        color: var.$color-white;
        opacity: 1;
        background: transparent;
        margin-top: -0.5rem;

        .max-number {
          line-height: 33px;}

        .number-divider {
          position: relative;
          display: inline-block;
          padding: 0px 3px;
          top: -1px;
        }
      }

      .indicator {
        position: absolute;
        width: 0%;
        height: 100%;
        bottom: 0px;
        left: 0px;
        background: var.$brand-color-primary;
        border-radius: 0.5rem;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }
}
