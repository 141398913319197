@mixin product-search-dialog() {
  app-product-search-dialog {
    position: relative;
    display: flex;
    flex-direction: column;

    .select-all-checkbox {
      position: absolute;
      // To compute: height-header-bar + (height-ag-header-container - height-checkbox) / 2
      // Currently: 50px + (35px - 40px) / 2 = 50px - 2.5px = 47.5px i.e. roughly 3rem
      top: 3rem;
      // without left grid border
      //left: -0.125rem;
      // with left grid border
      left: -1px;
    }

    .header-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .choose-csv {
        white-space: nowrap;
        max-width: fit-content;
        width: 100%;
      }

      #fileCSV {
        display: none!important;
      }

      .selected-count {
        white-space: nowrap;
      }

      app-grid-filter {
        width: 100%;
      }
    }
  } // app-product-search-dialog
}
