@mixin story-catalogue() {
  app-story-catalogue {
    display: flex;
    flex-direction: column;
    height: 100%;

    app-card {
      flex-grow: 2;
    }
  }
}
