@use 'src/assets/style/_variables.scss' as var;

@mixin content-download() {
  .content-download-dialog {
    .modal-content {
      max-height: calc(90vh - 70px);
    }
  }
  app-content-download-dialog {
    .attachments {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 0.5rem;
      overflow-y: auto;

      a {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        text-decoration: none;
        padding: 0.5rem;

        &:hover {
          background-color: #04040420;
        }
        span {
          flex: 1;
        }
      }
    }
  }
}
