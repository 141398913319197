@use 'src/assets/style/_variables.scss' as var;

@mixin salutation-preview() {
  app-salutation-preview {
    app-modal,
    .modal-content {
      height: 100%;
    }
    .label {
      font-size: 1.2rem;
      margin-top: 2rem;
    }
    .label:first-child {
      margin-top: 0;
    }
    .value {
      padding: 0 0 0 1rem;
      background: rgba(211, 211, 211, 0.5);
      border-left: 1px solid var.$color-grey-5;
    }
  }
}
