@use 'src/assets/style/_variables.scss' as var;

@mixin story-trigger-overview() {
  app-trigger-story-overview {
    .overview-container-parent {
      display: block;
      overflow: auto;
      height: 100%;
    }

    .overview-details-container {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: minmax(28rem, 50%) auto;
      grid-template-areas:
        'left left right right'
        'bottom bottom bottom bottom';
      gap: 1rem;
      padding: 1rem 1rem;

      app-story-overview-details {
        grid-area: left;
      }

      .right {
        grid-area: right;

        .fields {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }

      .bottom {
        grid-area: bottom;
      }
    }

    .trigger-actions {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
    }

    .contents-container {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: minmax(30rem, 50%) auto;
      grid-template-areas:
        'contents'
        'trigger-settings';
      gap: 1rem;
      padding: 1rem 1rem;

      .contents {
        grid-area: contents;
      }

      .trigger-settings {
        grid-area: trigger-settings;

        .card-body {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    .tab-content-container {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .trigger-panel {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: larger;
      background-color: var.$brand-color-third;
      border-radius: 25px;
      height: 15rem;
      width: 15rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
