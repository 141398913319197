@use 'src/assets/style/_variables.scss' as var;

@mixin help-navigation() {
  app-help-navigation {
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid var.$color-grey-5;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      .navigation-item,
      .navigation-subitem {
        display: flex;
        align-items: center;
        color: var.$brand-color-primary;
        cursor: pointer;
        margin-bottom: 0.5rem;
      }

      .navigation-item {
        > app-config-icon:hover,
        > span:hover {
          color: var.$accent-color;
        }

        .hyphen-symbol {
          display: block;
          font-size: 2rem;
          width: 2rem;
          text-align: center;

          &:hover {
            color: var.$brand-color-primary;
            cursor: default;
          }
        }
      }

      .sub-navigation-container {
        margin-bottom: 1rem;
        padding-left: 1rem;
      }

      .navigation-subitem {
        padding-left: 24px;

        &:hover {
          color: var.$accent-color;
        }

        > app-config-icon {
          font-size: 18px;
          width: 18px;
        }
      }
    }
  }
}
