@use 'src/assets/style/_variables.scss' as var;

@mixin product-details-form() {
  app-product-details-form {
    .action-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
    }

    .tags-wrapper {
      flex: 2;

      mat-form-field {
        width: 100%;
      }
    }
  }
}
