@mixin login() {
  app-login {
    .auth-container {
      position: fixed;
      top: 25%;
      left: 50%;
      transform: translateX(-50%);

      &.login {
        div[card-body] {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: column;
          gap: 1rem;

          button {
            padding-left: 8rem;
            padding-right: 8rem;
          }
        }
      }
    }
  }
}
