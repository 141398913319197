@use 'src/assets/style/_variables.scss' as var;

@mixin salutations() {
  app-salutations {
    .page-content-container {
      position: relative;
    }
    .salutation-container {
      flex: 1;
      display: flex;
      flex-direction: column;

      &:first-child {
        border-bottom: 1px solid var.$color-grey-6;
      }

      > app-grid {
        flex: 1;
      }
    }
  }
}
