@use 'src/assets/style/_variables.scss' as var;

@mixin objects-overview() {
  app-objects-overview {
    .list-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      app-client-catalogue,
      app-portfolio-list,
      app-product-catalogue,
      app-intermediary-list,
      app-employee-catalogue {
        flex: 1;
      }
    }
  }
}
