@use 'src/assets/style/_variables.scss' as var;

@mixin client-catalogue() {
  app-client-catalogue {
    display: flex;
    flex-direction: column;
    height: 100%;

    .grid-container {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
    }
    .card-body,
    .card-body > div {
      flex: 1;
      display: flex;
      flex-direction: row;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .card-header.h2 {
      display: flex;
      width: 100%;
      align-items: flex-start;
      margin-bottom: 1rem;

      > .card-header {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start !important;
      }
    }
    .header-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
    app-grid {
      width: 100%;
    }
  }
}
