@use 'src/assets/style/_variables.scss' as var;

@mixin story-hot-carousel() {
  app-story-hot-carousel {
    .hot-carousel-container {
      border-bottom: 1px solid var.$color-grey-6;
    }

    swiper-container::part(container) {
      height: 15rem;
      max-width: 116rem;
      margin: 0 auto;
    }

    swiper-container::part(button-prev) {
      display: block;
      --swiper-navigation-sides-offset: 0;
    }
    swiper-container::part(button-next) {
      display: block;
      --swiper-navigation-sides-offset: 0;
    }
    swiper-slide {
      padding: 1rem 3rem;
      max-width: 23rem;
      app-story-card {
        display: block;
      }
    }
  }
}
