@use 'src/assets/style/_variables.scss' as var;

@mixin template-default-details() {
  app-template-default-details {
    width: 100%;

    form {
      padding: 5px;
    }

    .row {
      gap: 10px;
    }

    .row > * {
      flex-grow: 1;
    }
  }
}
