@use 'src/assets/style/_variables.scss' as var;

@mixin monitoring-event-campaign() {
  app-monitoring-event-campaign {
    .event-campaign-container {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 1rem;

      .charts-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1rem;
        padding: 1rem 1rem 1rem 0;

        .chart-graph {
          display: flex;
          justify-content: stretch;
          align-items: stretch;
          flex: 1;
        }
      }
    }
  }
}
