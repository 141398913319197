@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin page() {
  app-page {
    .page-side-bar {
      position: fixed;
      top: 0;
      left: 0;
      width: 80px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: var.$brand-color-primary;
      color: var.$color-white;
      z-index: 1;
      transition: width 0.2s ease-in-out;

      &.hovering {
        width: 320px;
      } /* hovering */

      &.hover {
        .navigation {
          .home {
            justify-content: flex-start;
            gap: 1.25rem;

            .home-name {
              display: block;
            }
          }

          .navigation-item {
            justify-content: flex-start;
            gap: 1.25rem;

            .navigation-item-name {
              display: block;
              text-wrap: nowrap;
            }

            .highlight {
              width: 290px;
            }
          }
        }

        .helpers {
          align-items: flex-start;

          .separator {
            margin-left: 15px;
            width: 290px;
          }

          .helper-item {
            padding: 0 1.5rem;

            .helper-item-name {
              display: block;
              text-wrap: nowrap;
            }
          }
        }
      } /* hover */

      .navigation {
        display: flex;
        flex-direction: column;
        align-items: start;

        .home {
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: start;
          padding: 0 1.5rem;
          margin-bottom: 2px;
          cursor: pointer;

          .home-name {
            display: none;
          }
        }

        .navigation-item {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1.25rem 1.5rem;
          cursor: pointer;

          .navigation-item-name {
            display: none;
          }

          .highlight {
            position: absolute;
            background-color: var.$color-white;
            width: 50px;
            height: 2px;
            left: 15px;
            bottom: 10px;
          }
        }
      }

      .helpers {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 1.25rem;
        padding-bottom: 1.25rem;
        cursor: pointer;

        .separator {
          width: 50px;
          height: 2px;
          background-color: var.$color-white;
          margin-left: 15px;
        }

        .helper-item {
          display: flex;
          align-items: center;
          gap: 1.25rem;
          padding: 0 1.5rem;

          .helper-item-name {
            display: none;
          }
        }

        app-config-icon {
          cursor: pointer;
        }
      }
    }

    .page-header {
      position: fixed;
      top: 0;
      left: 80px;
      width: calc(100vw - 80px);
      height: 82px;
      display: flex;
      flex-direction: column;

      .header-content {
        height: 80px;
        padding: 0 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          display: flex;
          align-items: center;
          gap: 1.25rem;

          .logo {
            color: var.$brand-color-primary;
          }
        }

        .separator {
          width: 2px;
          height: 50px;
          background-color: var.$color-grey-6;
        }

        .user-menu {
          display: flex;
          align-items: center;
          gap: 1.25rem;
          cursor: pointer;

          .currency {
            display: flex;
            align-items: center;
            gap: 0.25rem;
          }

          .user {
            display: flex;
            align-items: center;
            gap: 0.25rem;
          }

          .language .mat-select-value {
            color: var.$brand-color-primary;
          }
        }
      }

      .header-separator {
        height: 2px;
        background-color: var.$color-grey-6;
      }
    }

    .page-body {
      position: fixed;
      top: 82px;
      left: 80px;
      width: calc(100vw - 80px);
      height: calc(100vh - 82px);
    }

    .language {
      min-width: 50px;
    }

  }
}
