@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin tab() {
  app-tab {
    .tab {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      height: 100%;
      cursor: pointer;

      &.active {
        color: var.$brand-color-primary;
        border-bottom: 2px solid var.$brand-color-primary;
      }
    }
  }
}
