@use 'src/assets/style/_variables.scss' as var;

@mixin distributor-overview() {
  app-distributor-overview {
    .list-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      app-distributor-clients,
      app-distributor-intermediaries,
      app-distributor-employees,
      app-distributor-portfolios {
        flex: 1;
        display: flex;
        flex-direction: row;

        app-grid {
          flex: 1;
        }
      }
    }
  }
}
