@use 'src/assets/style/_variables.scss' as var;
@use '@angular/material' as mat;

@mixin external-links() {
  app-external-links {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1.25rem;

    app-grid {
      flex: 1;
      .warning {
        background-color: mat.m2-get-color-from-palette(var.$state-palette-error, 50) !important;
        color: var.$state-color-error !important;
      }
    }

    button:first-child {
      align-self: flex-end;
    }
  }
}
