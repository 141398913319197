@use 'src/assets/style/_variables.scss' as var;

@mixin config-icon() {
  app-config-icon {
    display: flex;

    &.suitability-green {
      color: var.$state-color-success !important;
    }

    &.suitability-warn {
      color: var.$state-color-warning !important;
    }

    &.suitability-danger {
      color: var.$state-color-error !important;
    }

    &.suitability-unknown,
    &.suitability-pending {
      &:hover {
        color: var.$accent-color !important;
      }
    }
  }
}
