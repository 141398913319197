@use 'src/assets/style/_variables.scss' as var;

@mixin icons-component() {
  app-icons {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;

    app-config-icon {
      margin: 1rem;
      border: 1px solid var.$color-grey-3;
    }
  }
}
