@use 'src/assets/style/_variables.scss' as var;

@mixin filter-details-intermediaries() {
  app-filter-details-intermediary {
    .filter-intermediary-details {
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .toggles {
        padding-left: 1rem;
        display: flex;
        flex-direction: row;
      }
    }
    .intermediary-toggle-filter {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-top: 1rem;
      padding-bottom: 1rem;
      .filter-header {
        flex: 1;
      }
    }
    .sub-section {
      padding-bottom: 3px;
      border-bottom: 1px solid #3a36db;
    }
  } /* app-filter-details-intermediary */
}
