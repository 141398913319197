@use 'src/assets/style/_variables.scss' as var;

@mixin grid-filter-item-date() {
  app-grid-filter-item-date {
    .grid-filter-item {
      .grid-filter-item-content {
        form {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
