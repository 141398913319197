@use 'src/assets/style/_variables.scss' as var;

@mixin multi-purpose-button() {
  app-multi-purpose-button {
    .basic-flex {
      align-items: center;
      gap: 0.5rem;
    }
  }
}
