@use 'src/assets/style/_variables.scss' as var;

@mixin demo-questionnaire-form() {
  app-demo-questionnaire-form {
    .mat-mdc-menu-item-button .mat-mdc-menu-item-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }

    .demo-row {
      flex-wrap: unset;
      align-items: center;
    }

    .demo-row-title {
      padding-top: 1rem;
    }

    mat-checkbox, mat-radio-button {
      input {
        width: 30rem;
      }
    }
  }
}
