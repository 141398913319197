@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin monitoring-campaign-decentralized() {
  app-monitoring-campaign-decentralized {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
