@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin filter-range-weight() {
  app-filter-range-weight {
    app-tag {
      display: flex;
      justify-content: space-between;

      &.highlight {
        .mat-icon {
          &:hover {
            color: var.$accent-color;
          }
        }
      }

      .mat-icon {
        cursor: pointer;

        &:hover {
          color: var.$state-color-error;
        }
      }
    }

    .filter-content {
      padding: 0 0.5rem;

      .exposure-label {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
    }

    .filter-content,
    .exposure-slider {
      margin-top: 0.25rem;
    }

    .mat-mdc-slider.mdc-slider {
      width: 100%;
    }
  }
}
