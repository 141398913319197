@use 'src/assets/style/_variables.scss' as var;

@mixin home-chart() {
  app-home-chart {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .first-col {
      flex: 1;
      margin-right: 0.5rem;
    }

    .second-col {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      app-distributor-summary {
        padding: 0;

        .summary-row {
          border-bottom: unset;
          padding: 0.75rem;
        }
      }

      .link-row {
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem;
        cursor: pointer;
        color: var.$brand-color-primary;
      }
    }
  }
}

