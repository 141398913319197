@use 'src/assets/style/_variables.scss' as var;

@mixin grid-filter() {
  .aspark-modal {
    .modal-workflow-btn-container {
      position: sticky;
      bottom: 0;
      background: white;
      right: 0;
      left: 0;
    }
  }
  app-grid-filter {
    .grid-filter-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-right: 1.5rem;

      .grid-filter-border {
        border: 1px solid var.$color-grey-5;
        border-radius: 0.25rem;
      }

      .grid-filter {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0.25rem 0.5rem;
        max-width: 26rem;

        .filter-chips-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .placeholder {
            display: flex;
            align-items: inherit;
            height: 1.625rem;
            padding-top: 0.35rem;
            text-decoration: underline;
          }

          .active-filters {
            align-items: center;
            padding-left: 0.5rem; /*chip set has internal margin -8px*/

            mat-chip-set.nowrap > div:first-child {
              display: flex;
              flex-wrap: nowrap;
            }

            mat-chip {
              border-radius: 0.25rem;
              background-color: var.$color-grey-3;
              margin: 0 0.5rem 0 0;
              height: 1.5rem;

              .mdc-evolution-chip__action--primary {
                cursor: pointer;
              }
            }

            .filter-text {
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 6rem; /* set max width to avoid enormous chips */
            }
          }

          .overflow-box {
            flex: 1;
          }

          cursor: pointer;
        }

        > app-config-icon {
          cursor: pointer;
          color: var.$brand-color-primary;
        }
      }
      .icon-wrapper {
        display: flex;
        align-items: center;
        padding: 0.25rem 0 0.25rem 1.25rem;

        > app-config-icon {
          transform: scale(1, 1.2);
          cursor: pointer;
          color: var.$brand-color-primary;
        }
      }
    }
    .search-wrapper {
      margin-right: auto;
    }
    .search {
      width: 20rem;
      padding-right: 1rem;

      input {
        text-overflow: ellipsis;
      }

      app-config-icon {
        color: var.$color-grey-3;
      }
    }
    .mat-mdc-form-field {
      font-size: 0.875rem;
      --mat-form-field-container-height: 2.125rem;

      .mat-mdc-form-field-infix {
        padding: 0.5rem;
        max-height: 2.125rem;
        align-content: center;
      }

      .mat-mdc-form-field-icon-prefix {
        padding-left: 0.25rem;
      }
    }
    app-config-icon.icon-button {
      cursor: pointer;
    }
    app-config-icon.clear-button {
      padding-right: 1.8rem;
    }
  }
}
