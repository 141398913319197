@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin monitoring() {
  app-monitoring {
    .tab-container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .tab-actions {
      gap: 0.5rem;
    }

    app-monitoring-campaign,
    app-monitoring-relationship-manager {
      flex: 1;
    }

    app-monitoring-campaign-overview,
    app-monitoring-event-campaign {
      flex: 1;
    }

    .list-container {
      flex: 1;
    }

    .catalogue-container {
      gap: 0;
      width: 100%;
      height: 100%;
      display: flex;


      app-monitoring-charts-card {
        .card {
          max-height: 100%;

          .card-body {
            max-height: calc(100% - 48px);
            overflow-y: auto;

            div[card-body] {
              max-width: 100%;
              max-height: 100%;

              app-chart {
                flex-grow: 25%;

                .chart-container {
                  max-height: 100%;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }

    .overview-container {
      display: grid;
      width: 100%;
      grid-template-rows: 50rem 40rem 40rem;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      & > app-card,
      & > .flex-row-wrapper,
      & > .chart-card {
        grid-column: span 2;
        width: 100%;
        max-width: 100%;
      }
    }

    .flex-row-wrapper {
      width: 100%;
      display: flex;
      gap: 1rem;

      app-card,
      app-chart {
        flex: 1;
      }
    }

    .chart-card {
      div[card-body] {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        app-chart {
          width: 25%;
        }
      }
    }
  }
  app-monitoring-campaign-overview {
    mat-button-toggle > button {
      @include mat.m2-typography-level(typo.$config, 'headline-5');
      font-weight: normal;
    }
  }
}
