@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin app-message-edit-dialog() {
  app-message-edit-dialog {
    height: 99%;
    > form {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto 1fr;
      grid-template-areas:
          "title      title"
          "hub        hub"
          "valid-from valid-to"
          "roles      roles"
          "content    content";
      gap: 1rem;

      .msg-title {
        grid-area: title;
      }

      .msg-hub {
        grid-area: hub;
      }

      .msg-valid-from {
        grid-area: valid-from;
      }

      .msg-valid-to {
        grid-area: valid-to;
      }

      .msg-roles {
        grid-area: roles;
      }
      // make the froala text editor fill the space
      .fr-box {
        display: flex;
        height: 100%;
        flex-direction: column;
        .fr-wrapper {
          flex: 1;
        }
      }
      // add the required asterisk to the label
      .required {
        mat-label::after {
          margin-left: 1px;
          margin-right: 0px;
          content: "*";
        }
      }

      // update label text color on error and remove padding bottom
      .error {
        padding-bottom: 0;

        mat-label, mat-error {
          color: var(--mat-form-field-error-text-color);
        }

        mat-error {
          padding-left: 1rem;
          font-family: var(--mat-form-field-subscript-text-font);
          line-height: var(--mat-form-field-subscript-text-line-height);
          font-size: var(--mat-form-field-subscript-text-size);
          letter-spacing: var(--mat-form-field-subscript-text-tracking);
          font-weight: var(--mat-form-field-subscript-text-weight);
        }

        .fr-box.fr-basic {
          .fr-top {
            border-top-color: var(--mat-form-field-error-text-color);
            border-left-color: var(--mat-form-field-error-text-color);
            border-right-color: var(--mat-form-field-error-text-color);
          }

          .fr-wrapper {
            border-left-color: var(--mat-form-field-error-text-color);
            border-right-color: var(--mat-form-field-error-text-color);
          }

          .fr-second-toolbar {
            border-left-color: var(--mat-form-field-error-text-color);
            border-right-color: var(--mat-form-field-error-text-color);
            border-bottom-color: var(--mat-form-field-error-text-color);
          }
        }
      }

      .msg-content {
        grid-area: content;
        flex-direction: column;
        flex-wrap: unset;
        padding-bottom: 22px;
        word-break: break-word;

        mat-label, mat-error {
          font-size: 0.875rem;
        }

        mat-label {
          color: var(--mdc-filled-text-field-label-text-color);
        }
      }
    }
  }
}
