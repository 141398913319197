@use 'src/assets/style/_variables.scss' as var;

@mixin grid-filter-form() {
  app-grid-filter-form {
    padding-bottom: 1rem;
    .grid-active-filter-container {
      display: flex;
      flex-wrap: wrap;
      padding-top: 0.5rem;

      .placeholder {
        display: flex;
        align-items: center;
        color: var.$color-grey-3;
        height: 1.5rem;
        margin-bottom: 0.5rem;
      }

      .spacer {
        flex: 1;
      }

      .active-filters {
        align-items: center;
        padding-left: 0.5rem; /*chip set has internal margin -8px*/

        .mat-divider {
          --mat-divider-width: 0.125rem;
          border-color: var.$color-grey-6;
          margin: 0 0.5rem 0.5rem 0.5rem;
        }

        .clear-all-filters {
          background-color: transparent;

          .mdc-evolution-chip__action--presentational {
            cursor: pointer;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
        }

        mat-chip {
          border-radius: 0.25rem;
          background-color: var.$color-grey-3;
          margin: 0 0.5rem 0.5rem 0;
          height: 1.5rem;
        }

        .filter-text {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 10vw; /* set max width to avoid enormous chips */
        }
      }
    }

    .grid-filter-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1.25rem;
      padding-right: 0.25rem;
    }
    .load-more {
      background: rgba(255,255,255,0.5);
      padding: 10px;
      position: absolute;
      bottom: 5rem;
      right: 3%;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }

    // add styles here to avoid duplication and grid filter items should anyway not be used outside of the grid filter form
    .grid-filter-item {
      border: 1px solid var.$color-grey-5;
      border-radius: 0.25rem;
      min-height: 14rem;

      .grid-filter-item-title {
        padding: 0.75rem 1rem;
        border-bottom: 2px solid var.$color-grey-5;
      }

      .grid-filter-item-content {
        padding: 0.75rem 1rem;
      }
    }
  }

  .grid-filter-container {
    .grid-filter-item-title {
      background-color: #ddd;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      &.is-selected {
        background-color: #888;
        padding-right: 0.5rem;

        .filter-header-name {
          color: white;
        }
        .filter-header-value {
          color: white;
        }
        app-config-icon {
          color: white;
          cursor: pointer;
        }
        .mdc-button__label app-config-icon {
          display: none;
        }
      }
      .filter-header {
        width: 100%;
        display: flex;
        flex-direction: row;

        .mdc-button__label {
          flex: 1;
          text-align: left;
          display: flex;
          flex-direction: row;

          .filter-header-name {
          }
          .filter-header-value {
            flex: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .mat-menu-filter {
    min-width: 22rem;
    padding: 1rem;
  }
  .grid-filter-item {
    min-width: 20rem;
  }
  .grid-filter-apply {
    width: 100%;
    margin-top: 4px;
  }
}
