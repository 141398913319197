@use 'src/assets/style/_variables.scss' as var;

@mixin content-form() {
  .custom-content-padding {
    .modal-content {
      padding: 0 !important;
    }
  }
  app-content-form {
    flex: 1;

    mat-tab-header {
      display: none;
    }

    .custom-content-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      max-height: calc(90vh - 70px); // Overflow is not working correctly otherwise (modalHeight - title)

      mat-tab-header {
        display: unset;
      }

      .form-container {
        padding: 1rem 1rem 0; // Add modal content padding just to content (excluding bottom buttons)
        overflow: auto;
        min-height: calc(90vh - 70px - 77px); // Making sure that buttons are at the bottom (modalHeight - title - buttons)

        &.ebanking-padding {
          padding-top: 0.5rem; // For e-banking preview has 3*3px grid-gaps w/o content (looks like 9px padding)
        }
      }

      .modal-workflow-btn-container {
        z-index: 2; // Needed to be on top of the form
        flex-direction: row !important;
        justify-content: space-between;
      }
    }
  }
}
