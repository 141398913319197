/* You can add global styles to this file, and also import other style files */
@import '_palette.scss';
@import '_variables.scss';
@import '_mixins.scss';
@import '_fonts.scss';
@import '_theme.scss';
// begin-import third party css
@import '../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';
@import '../../../node_modules/froala-editor/css/froala_editor.pkgd.css';
// end-import third party css
@import 'ngx-toastr/toastr';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
// import Swiper and modules styles
/*
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
*/

@import '../../../node_modules/video.js/dist/video-js.min.css';
@import '../../../node_modules/videojs-record/dist/css/videojs.record.min.css';

// css reset
html {
  // eqvestee theme
  font-family: #{'Poppins', 'sans-serif'};
  line-height: 130%;
  font-size: 16px;
  font-weight: 400;
  color: $color-black-2;
  // general properties
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  overflow: hidden;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .invisible {
    visibility: hidden;
  }

  app-config-icon {
    font-size: 24px; /* Preferred icon size */
    width: 24px;
  }

  .material-symbols-outlined {
    font-size: inherit;
    color: inherit;
    width: inherit;
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  mat-slider > mat-slider-visual-thumb > .mdc-slider__value-indicator-container {
    bottom: 38px;
    .mdc-slider__value-indicator {
      transform: scale(1);
      background-color: transparent;
      color: black;

      &::before {
        border-bottom: 6px solid;
        border-bottom-color: transparent;
        top: 5px;
        bottom: unset;
        border-top: unset;
      }
      &::after {
        top: unset;
        bottom: 0;
      }
    }
  }
}
