@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin email-dialog() {
  app-email-dialog {
    mat-form-field {
      width: 100%;
    }
  }
}
