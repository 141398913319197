@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin app-message-view-dialog() {
  app-message-view-dialog {
    padding: 1rem;
    min-width: 20rem;
    min-height: 20rem;
    // max-dialog-height - header - modal-content-padding - modal-actions - app-message-view-dialog-padding (x2: top and bottom)
    max-height: calc(90vh - 70px - 1rem - 77px - 2rem);
    .app-message-content {
      width: 100%;
      height: 100%;
    }
  }
}
