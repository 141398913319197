@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin story-card() {
  app-story-card {
    .story-container {
      position: relative;
      border-radius: 0.5rem;
      background-color: var.$color-white;
      box-shadow: var.$box-shadow;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .image {
        height: 100%;
        width: 100%;
        aspect-ratio: 16 / 9;
        background-position: center 45%;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .story-title {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      background-color: var.$brand-color-primary;
      color: var.$color-white;
      padding: 1rem 1.3rem;
      div {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 93%;
        min-height: 38px;
      }
    }

    &:hover [data-linked='true'] {
      cursor: pointer;
      .story-title {
        background-color: var.$color-grey-6;
        color: var.$brand-color-primary;
      }
    }
  }
}
