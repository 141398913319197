@use 'src/assets/style/_variables.scss' as var;

@mixin employee-collection-picker() {

  app-employee-collection-picker {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'name . is-public'
      'selected actions available'
      'dialog-actions dialog-actions dialog-actions';
    width: 100%;
    height: auto;

    .collection-name {
      grid-area: name;
      width: 100%;
    }

    .is-public {
      grid-area: is-public;
      display: flex;
      align-items: center;
      padding-bottom: 0.5rem; // adjust for error message padding of form field

      column-gap: 1rem;

      app-config-icon {
        color: var.$brand-color-primary;
      }
    }

    .actions {
      grid-area: actions;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 1rem;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem;
      }
      .mat-icon {
        margin-right: 0;
        margin-left: 0;
      }
    }

    .available {
      .card-header-container {
        display: none;
      }
      grid-area: available;
      height: 100%;
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
      .grid-basic-toolbar {
        max-height: 2.5rem;
      }
      .select-all-checkbox-container {
        position: absolute;
        top: 0.85rem;
        left: 1.35rem;
        display: inline-flex;

        .overlay-spinner {
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.5);
          z-index: 1;
          margin: 0.5rem;
        }

        .selected-entries-count {
          padding-left: 0.5rem;
          align-content: center;
          margin-top: 0.25rem; // the content is not centered correctly
        }
      }
    }

    .selected {
      .card-header-container {
        display: none;
      }
      grid-area: selected;
      height: 100%;
    }

    .dialog-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 1.25rem 0 1.25rem 1.5rem;
      gap: 1.25rem;
      grid-area: dialog-actions;
    }
  }
}
