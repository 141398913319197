@mixin campaign-suitability-summary() {

  app-campaign-suitability-summary {
    --min-height: 25rem;
    display: flex;
    flex-direction: column;
    min-height: var(--min-height);

    .ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
      min-height: calc(var(--min-height) - 3rem);
    }

    .suitability-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .status {
        display: flex;
        align-items: center;

        app-config-icon {
          width: 1.5rem;
          height: 1.5rem;
          margin-left: 4px;

          .mat-icon {
            font-size: 1.5rem;
          }
        }
      }
    }

    div[col-id='state'] > .ag-cell-wrapper {
      height: 100%;
    }
  }
}
