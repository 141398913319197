@use 'src/assets/style/_variables.scss' as var;

@mixin portfolio-overview() {
  .portfolio-overview-container {
    flex: 1;
  }
  .portfolio-details-container{
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 1rem;

    .detail-items-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .inside-col {
        margin-right: auto;
        margin-left: auto;
      }
      .end-col {
        margin-left: auto;
      }
      .detail-item {
        padding-bottom: 1rem;
      }
    }
  }
}
