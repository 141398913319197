@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-overview-details() {
  app-campaign-overview-details {
    min-width: 45rem;

    .campaign-title > span {
      width: 100%;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
    }

    .card-body > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .content-wrapper {
        display: grid;
        grid-template-columns: 60% 1fr;
      }

      .actions-wrapper {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 24px;

        button + button {
          margin-right: 12px;
        }
      }

      .detail-items-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex: 1;
        min-width: 55%;
      }

      .detail-items {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }
      .detail-item.body {
        .label {
          min-width: 11rem;
          max-width: none;
        }
        .value {
          max-width: 20rem;
        }
      }
      .detail-spacer {
        flex: 1;
      }

      .image-details {
        display: flex;
        flex-direction: column;

        .preview-container {
          flex: 0 0 40%;
          box-shadow: var.$box-shadow;

          .image {
            height: 100%;
            width: 100%;
            aspect-ratio: 16 / 9;
            background-position: center 45%;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }

  }
}
