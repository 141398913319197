@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin tag() {
  app-tag {
    display: flex;

    .selectable {
      cursor: pointer;
    }

    .tag {
      display: flex;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      z-index: 1;
      position: relative;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      flex-grow: 1;
      border: 1px solid var.$color-black-2;
    }

    .mat-select-arrow {
      color: var.$accent-color;
    }

    .delete-tag {
      &:hover {
        color: var.$state-color-error;
        cursor: pointer;
      }
    }

    &.disabled {
      color: var.$color-grey-3 !important;

      .tag {
        border: 1px dashed var.$color-grey-3 !important;

        .delete-tag {
          display: none;
        }
      }
    }
  }
}
