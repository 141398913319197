@use 'src/assets/style/_variables.scss' as var;

@mixin bg-shape() {
  app-bg-shape {
    position: relative;
    z-index: -1;
  }

  .shape-outer {
    position: fixed;
    display: flex;
    flex-shrink: 0;
    height: calc(150px + 4vw);
    width: calc(150px + 4vw);
    margin: 25px;
    // background-image: linear-gradient(to bottom right, #1d426a, #3f5e80, #25b0a9);
    background-image: linear-gradient(
      to bottom right,
      #5f6062,
      #e5e3d7,
      #f0f0e8
    );
    opacity: 0.2;

    &.big {
      height: calc(170px + 4vw);
      width: calc(170px + 4vw);
    }

    &.bigger {
      height: calc(220px + 4vw);
      width: calc(220px + 4vw);
    }
  }

  .shape-inner {
    height: calc(130px + 4vw);
    width: calc(130px + 4vw);
    background: #eee;
    background-size: cover;
    margin: auto;

    &.big {
      height: calc(150px + 4vw);
      width: calc(150px + 4vw);
    }

    &.bigger {
      height: calc(200px + 4vw);
      width: calc(200px + 4vw);
    }
  }

  .octagon {
    -webkit-clip-path: polygon(
      30% 0%,
      70% 0%,
      100% 30%,
      100% 70%,
      70% 100%,
      30% 100%,
      0% 70%,
      0% 30%
    );
    clip-path: polygon(
      30% 0%,
      70% 0%,
      100% 30%,
      100% 70%,
      70% 100%,
      30% 100%,
      0% 70%,
      0% 30%
    );
  }

  .decagon {
    -webkit-clip-path: polygon(
      50% 0%,
      80% 10%,
      100% 35%,
      100% 65%,
      80% 90%,
      50% 100%,
      20% 90%,
      0 65%,
      0 35%,
      20% 10%
    );
    clip-path: polygon(
      50% 0%,
      80% 10%,
      100% 35%,
      100% 65%,
      80% 90%,
      50% 100%,
      20% 90%,
      0 65%,
      0 35%,
      20% 10%
    );
  }
}
