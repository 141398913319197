@use 'src/assets/style/_variables.scss' as var;

@mixin system-status-component() {
  app-system-status {
    position: relative; /* for the app-picker-toolbar */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 15% auto;
    height: 100%;

    mat-label {
      font-weight: bolder;
    }

    .error-status.ag-row-odd, .error-status.ag-row-even {
      background-color: var.$graph-color-3;
    }

    .summary {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .description {
      font-size: small;
    }

    .entry {
      font-size: larger;
      display: flex;
      padding: 0.5rem;
      gap: 1rem;

      .left {
        text-align: right;
        width: 20%;
      }

      .right {
        width: 80%;
      }
    }
  }
}
