@use 'src/assets/style/_variables.scss' as var;

@mixin my-salesforce() {
  app-my-salesforce {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 1rem;
    mat-form-field {
      flex: 1;
    }
    button {
      height: calc(100% - 1rem)!important;
    }
  }
}
