@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin modal() {
  app-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .aspark-modal {
    .modal-title {
      display: flex;
      justify-content: space-between;
      padding: 1.25rem 1.5rem;
      border-bottom: 4px solid var.$color-grey-5;
      background-color: var.$brand-color-primary;
      color: var.$color-white;

      .modal-title-actions {
        display: flex;

        app-config-icon {
          cursor: pointer;
        }

        &__submit-filters {
          margin-right: 0.5rem;
        }
      }
    }

    .modal-content {
      padding: 1rem 1rem 0;
      display: flex;
      overflow: auto;
      flex: 1;

      > * {
        flex: 1;
      }

      &.confirmation-modal {
        flex-direction: column;
        align-items: center;
        gap: 1.25rem;
        padding: 2.5rem;

        > mat-form-field {
          width: 100%;
        }
      }
    }

    .modal-workflow-btn-container {
      display: flex;
      flex-direction: row-reverse;
      padding: 1.25rem 1.5rem;
      gap: 1.25rem;
      border-top: 1px solid var.$color-grey-5;

      &.confirmation-modal-btn-container {
        justify-content: center;
        border: none;
        padding: 0 2.5rem 2.5rem;
      }
    }
  }
}
