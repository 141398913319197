@mixin campaign-action-processing() {
  app-campaign-action-processing {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .col {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      &.bulk-edit-col {
        flex: 1;
        width: 100%;
      }
    }

    .summary {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;

      .row {
        height: 36px;
        margin-right: 2rem;
        align-items: center;

        .h4 {
          margin-right: 1rem;
        }
      }
    }
    .hint {
      display: block;
      max-width: 72rem;
      padding-bottom: 2rem;
    }

    app-grid {
      flex: 1;
      min-width: 60vw;
    }
    .card-body-charts {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      min-width: 15vw;
      border-left: 1px solid var.$color-black-1;
    }
    app-chart {
      flex: 1;
      .chart-container {
        height: 100%;
      }
    }

    .bulk-edit {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 2rem;
    }
  }
}
