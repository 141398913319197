@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-action-sender-selection() {
  app-campaign-action-sender-selection {
    display: flex;
    flex-direction: column;
    flex: 1;

    .warning-message {
      padding: 10px;
      border-radius: 4px;
      background-color: var.$state-color-error;
      color: var.$color-black-1;
    }
  }
}
