@mixin statistics() {
  app-statistics {
    display: flex;
    width: 100%;
    gap: 1rem;

    app-progress-card {
      flex: 1;
    }

    &.horizontal {
      flex-direction: row;
      max-width: none;
      gap: 2rem;

      app-progress-card {
        flex-grow: 1;
        margin-bottom: 0;
      }
    }
  }
}
