
@mixin client-publication-types() {
  app-client-publication-types {
    .mat-slide-toggle.mat-disabled {
      opacity: 0.8;
    }
    #explanation {
      margin-top: 1rem;
      color: #666;
    }
  }
}
