@use 'src/assets/style/_variables.scss' as var;

@mixin link-creator() {
  app-link-creator {
    display: block;
    height: 100%;

    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "link link"
        "target ident";

      .form-link {
        grid-area: link;
      }
      .form-target {
        grid-area: target;
      }
      .form-ident {
        grid-area: ident;
      }
    }
  }
}
