@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-action-toolbar() {
  app-campaign-action-toolbar {
    app-dropdown-selection-bar {
      mat-select {
        width: 12rem;
      }
    }
    app-sub-header .sub-header-actions {
      flex: 1;

      .spacer {
        flex: 1;
      }
    }
  }
}
