@use 'src/assets/style/_variables.scss' as var;

@mixin org-position-users() {
  app-org-position-users {
    display: flex;
    flex-direction: column;

    .dialog-content {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: stretch;
    }

    .dialog-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 1.25rem;
      padding: 1.25rem 1.5rem;
    }

    app-grid {
      flex: 1;
      min-width: 30vw;
      min-height: 30vh;

      ag-grid-angular {
        padding-bottom: 0 !important;
      }

      .grid-basic-toolbar {
        display: none;
      }

      .grid-filter-wrapper {
        padding-right: 0;
      }

      .ag-root-wrapper-body {
        border: unset;
      }
    }

    app-grid:first-child {
      min-height: calc(30vh - 60px);
      .ag-cell-value {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .user-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 1rem;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem;
      }
      > .spacer {
        min-height: 1rem;
      }
      .mat-icon {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}
