@use 'src/assets/style/_variables.scss' as var;

@mixin progress-update() {
  app-progress-update {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;

    .header,
    .progress {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .progress-value {
      align-self: center;
    }

    .progress-error {
      color: var.$state-color-error;
    }
    .spacer {
      flex: 1;
      background-color: white;
      z-index: 1000;
    }
  }
}
