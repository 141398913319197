@use 'src/assets/style/_variables.scss' as var;

@mixin demo-conversion-rate-dialog() {
  app-demo-conversion-rate-dialog {
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    .dialog-container {
      flex: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: "exposure estimated" "portfolios portfolios";
      width: 90vw;

      &.is-selected {
        grid-template-areas: "exposure estimated" "portfolios selected";

        .selected {
          display: block;
        }
      }
    }

    highcharts-chart {
      display: block;
    }
    .exposure {
      grid-area: exposure;
      margin: 0.5rem;
    }
    .estimated {
      grid-area: estimated;
      width: calc(45vw - 3rem);
      margin: 0.5rem;
    }
    app-grid[tableId="portfolio-list-conversion-rate"] {
      grid-area: portfolios;

      ag-grid-angular {
        height: inherit;
      }
      .grid-basic-toolbar {
        height: 35px;
      }
    }
    .selected {
      display: none;
      grid-area: selected;
      width: calc(45vw - 3rem);
      margin: 0.5rem;
      position: relative;

      .selected-prev,
      .selected-next {
        display: none;
      }

      .selected-prev {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
      }

      .selected-next {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
      }

      swiper-container::part(pagination) {
        --swiper-pagination-bottom: -4px;
      }
    }
    swiper-slide.score {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
    }
    .score-container {
      border: 2px solid black;
      border-radius: 4px;
      background: white;
      display: flex;
      flex-direction: column;
      color: white;

      .score-head {
        background: #3A36DB;
        font-size: 1.2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem;

        .score-head-icon {
          border: 2px solid black;
          border-radius: 50%;
          padding: 0.5rem;
          text-align: center;
          background: white;
          color: #3A36DB;
        }
        .score-head-title {
          flex: 1;
          text-align: center;
        }
      }
      .score-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem;

        section {
          flex: 1;
          display: flex;
          flex-direction: column;
          border: 2px solid black;
          border-radius: 0.5rem;
          background: #3A36DB;
          padding: 0 0.5rem;

          h1 {
            font-size: 1.2rem;
          }
          ul {
            margin: 0;
            list-style-type: none;
            position: relative;
          }
          li::before {
            content: '▶';
            position: absolute;
            left: 1rem;
          }
        }
      }
    }
  }
}
