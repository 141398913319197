@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin chart() {
  app-chart {
    .chart-container {
      position: relative;
      width: 100%;

      mat-spinner {
        margin: 0 auto;
      }

      .spinner-container {
        position: absolute;
        top: -3rem;
        bottom: -2rem;
        left: -2rem;
        right: -2rem;
        padding-top: 0.25rem;
        display: flex;
        justify-content: center;
        border-radius: 0.5rem;
        background-color: adjust-color(var.$brand-color-primary, $alpha: -0.7);
        z-index: 100;
      }

      .info-container {
        display: flex;
        flex-direction: row-reverse;
        height: 2rem;
      }
    }
    .not-all-scatter-show {
      display: none;
    }

    highcharts-chart {
      display: block;
      width: 100%;
      height: calc(100% - 5px);

      &.chart-with-warning {
        height: calc(100% - 24px);
      }

      g.highcharts-series.highcharts-series-0.highcharts-pie-series {
        transform: translate(10, 10) scale(1, 1) !important;
      }
    }
  }
}
