@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin sub-header() {
  app-sub-header {
    .sub-header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 73px;
      padding: 0.75rem 1.5rem;
      gap: 1rem;
      border-bottom: 1px solid var.$color-grey-6;

      &.small {
        height: 61px;
        font-weight: normal;
      }

      .header {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 50vw;
      }

      .sub-header-content {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        .breadcrumbs {
          display: flex;
          align-items: center;
          gap: 0.75rem;

          .breadcrumb {
            @include mat.m2-typography-level(typo.$config, 'body-3');
            color: var.$brand-color-primary;
            cursor: pointer;

            &.active {
              @include mat.m2-typography-level(typo.$config, 'headline-6');
              color: var.$color-black-2;
              cursor: auto;
            }
          }

          .separator {
            background-color: var.$color-black-1;
            width: 1px;
            height: 0.75rem;
          }
        }
      }

      .sub-header-actions {
        display: flex;
        align-items: center;
        gap: 1.25rem;

        app-config-icon {
          cursor: pointer;
        }
      }
    }
  }
}
