@use 'src/assets/style/_variables.scss' as var;

@mixin overview-detail-item() {
  app-overview-detail-item {
    .detail-item {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;

      .warning {
        color: var.$state-color-warning;
      }

      .label {
        min-width: 10rem;
        max-width: 100px;
        display: inline-block;
      }
      .value {
        display: flex;
        flex-direction: row;
      }
      .value > app-config-icon {
        cursor: pointer;
        padding-left: 0.3rem;
      }
      .value > span {
        max-width: 250px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        margin-left: 0.5rem;
        align-self: center;
      }
      .value.clickable {
        cursor: pointer;
        color: var.$brand-color-primary;
      }
      .value.no-label,
      .value.no-label > span {
        max-width: 25em;
      }
    }
  }
}
