@use 'src/assets/style/_variables.scss' as var;

@mixin table-selection-bar() {
  app-table-selection-bar {
    width: 100%;
    font-size: 0.875rem;

    .h4 {
      font-size: 0.875rem;
    }

    .toolbar-actions {
      display: flex;
      margin-left: auto;
      gap: 0.5rem;

      .actions-title {
        align-self: center;
        color: var.$color-black-1 !important;
      }
    }

    .table-selection-bar {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

    mat-checkbox {
      margin-right: 0.5rem;
    }

    mat-select {
      width: 12rem;
    }
    .mat-mdc-select {
      font-size: 0.875rem;
    }
    app-dropdown-selection-bar {
      margin-left: auto;
    }
    app-grid-filter {
      width: 100%;

      .grid-filter-wrapper {
        padding-right: unset !important;
      }
    }
  }
}
