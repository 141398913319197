@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-send() {
  app-campaign-send {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    mat-button-toggle-group.portfolio-list-type {
      .mat-button-toggle-label-content {
        padding: 0.25rem;
      }
      mat-pseudo-checkbox {
        display: none;
      }
    }

    .sub-header-actions {
      flex: 1;
    }
    app-campaign-portfolio-list,
    app-campaign-action-list {
      flex: 1;
    }

  }
}
