@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-action-edit() {
  app-campaign-action-edit {
    .field-label {
      border-bottom: 1px solid;
      border-bottom-color: var.$color-grey-3;
    }
    .form-fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 1rem;
      .field {
        column-span: 1;
      }
      .field-double {
        grid-column: span 2;
      }
    }
  }
}
