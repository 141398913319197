@use '_variables.scss' as var;

@mixin bigScreen {
  @media #{map-get(var.$mediaQueries, 2k)} {
    @content;
  }
}

@mixin full-HD {
  @media #{map-get(var.$mediaQueries, full-HD)} {
    @content;
  }
}

@mixin small {
  @media #{map-get(var.$mediaQueries, small)} {
    @content;
  }
}
