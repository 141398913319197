@use 'src/assets/style/_variables.scss' as var;

@mixin block-value() {
  app-block-value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: var.$box-shadow;

    .title {
      margin-bottom: 0.5rem;
    }
    .value {
      text-align: center;
    }
  }
}
