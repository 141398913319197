@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin app-messages() {
  app-messages {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    app-grid {
      height: 100%;
      flex: 1;
    }
  }
}
