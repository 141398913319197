@use '@angular/material' as mat;
@use '_palette.scss' as pal;

// colors
$accent-palette: mat.m2-define-palette(pal.$accent);
$accent-color: mat.m2-get-color-from-palette($accent-palette, 500);
$brand-palette-primary: mat.m2-define-palette(pal.$brand-primary);
$brand-color-primary: mat.m2-get-color-from-palette($brand-palette-primary, 500);
$brand-color-secondary: #4844de;
$brand-color-third: #e8f0fb;
$state-color-disable: #a6a4ef;
$state-color-success: #27ae60;
$state-color-warning: #f8c529;
$state-palette-error: mat.m2-define-palette(pal.$state-error);
$state-color-error: mat.m2-get-color-from-palette($state-palette-error, 500);
$color-black-1: #000000;
$color-black-2: #1d1d1d;
$color-sub-white: #fafafa;
$color-white: #ffffff;
$color-grey-1: #333333;
$color-grey-2: #4f4f4f;
$color-grey-3: #828282;
$color-grey-4: #dbdbdb;
$color-grey-5: #e0e0e0;
$color-grey-6: #f4f4f4;
$color-light-green: #d9ed92;
$color-light-blue: #bbdefb;
$graph-color-1: #157afe;
$graph-color-2: #219ebc;
$graph-color-3: #e76f51;
$graph-color-4: #588157;
$graph-color-5: #3d4058;
$graph-color-6: #985de5;
$graph-color-7: #80ed99;
$graph-color-8: #e85d04;
$graph-color-9: #c9184a;
$graph-color-10: #40916c;

// other
$header-height: 60px;
$tab-header-height: 49px;
$box-shadow: (0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24));

// filter sizes
$grid-filter-height: 2.125rem;
$grid-filter-padding: 0.5rem;

$mediaQueries: (
  small: '(max-width: 1919px)',
  full-HD: '(min-width: 1920px)',
  2k: '(min-width: 2560px)',
);
