@mixin distributor-detail-view {

  .distributor-details-container {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: minmax(40%, auto) 1fr;
    grid-gap: 1rem;

    .half-row {
      grid-column: span 2;
    }

    .full-row {
      grid-column: 1 / -1;
    }

    .flex-end {
      flex: 1;
    }

    .flex-row {
      grid-column: 1 / -1;
      display: flex;
      flex-direction: row;
      column-gap: 1rem;

      &.max-size {
        grid-row: 1 / -1;
      }
    }

    .more-space-for-value {
      .value {
        overflow: hidden;
        > span {
          max-width: unset;
        }
      }
    }

    // use this class to divide details items in row direction
    .distributor-details {

      .detail-items-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(24rem, 30rem));
        grid-auto-flow: row;

      }
    }

    app-distributor-summary {
      // there is already padding of left: 1rem (gap) and right: 1.5rem (page-content-container)
      padding: 1.1rem 1.75rem 1rem 2.25rem;

      .summary-header {
        margin-top: -0.6rem; // compensate for space created by line-height (130%): 2rem*0.3
      }
    }
  }
  app-distributor-portfolio-overview {
    .distributor-details-container {
      flex: 1;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 40%;
      grid-template-rows: minmax(40%, auto) 1fr;
      grid-template-areas:
      "details   clients"
      "positions positions";
      gap: 1rem;

      app-card.details {
        grid-area: details;

        .detail-items-wrapper {
          display: grid;
          grid-template-columns: repeat(auto-fit, 24rem);
          grid-auto-flow: row;
          justify-content: space-between;
          row-gap: 1rem;
          margin-right: 1.5rem;
        }
      }

      app-card.positions {
        grid-area: positions;

        app-grid {
          width: 100%;
          height: 100%;
        }

        .card-header-container {
          display: none;
        }
      }

      app-card.clients {
        grid-area: clients;

        app-grid {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
