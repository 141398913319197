@use 'src/assets/style/_variables.scss' as var;

@mixin filter-details() {
  app-filter-details {
    display: flex;

    .mat-mdc-tab-header {
      z-index: 2;
      background-color: var.$color-white;
      border-bottom: 1px solid var.$color-grey-5;
    }

    mat-tab-group {
      width: 100%;
    }

    .filter-details-client-container,
    .filter-details-portfolio-container,
    .filter-details-position-container {
      padding-top: 1rem;

      .h3,
      .h4 {
        display: block;
        padding: 0 1.25rem;
        margin-bottom: 1rem;
      }

      .h4 {
        padding-bottom: 3px;
        border-bottom: 1px solid var.$brand-color-primary;
      }

      .filter-details-row + .h4 {
        margin-top: 1rem;
      }

      .filter-details-row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        app-filter {
          margin-left: 2rem;
          margin-right: 2rem;

          mat-form-field:not(.min-max-form-field) {
            width: 100%;
          }

          .ngx-slider {
            margin-top: 47px;
          }
        }
      }
    }

    .filter-details-client-container,
    .filter-details-position-container,
    .filter-details-portfolio-container {
      .filter-details-row {
        app-filter {
          width: calc((100% / 3) - 4rem);
        }
      }
    }
  }

  .mat-mdc-menu-panel,
  .mat-select-panel-wrap > div[role='listbox'] {
    overflow-x: hidden;
  }
}
