@use 'src/assets/style/_variables.scss' as var;

@mixin my-settings-overview() {
  app-my-settings-overview {
    .list-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      app-my-collections,
      app-my-dynamic-users,
      app-my-favorite-currencies,
      app-my-intermediary-collections{
        flex: 1;
      }
    }

    .tabs-panel-with-button {
      display: flex;
      flex-direction: row;

      app-tabs-panel {
        flex: 1;
      }

      .sub-header-actions {
        padding: 0.75rem 1.5rem;
        justify-content: flex-end;
        background-color: var.$color-grey-6;
      }
    }
  }
}
