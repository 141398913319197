@use 'src/assets/style/_variables.scss' as var;

@mixin content-attachments() {
  app-content-attachments {
    .files {
      display: flex;
      flex-direction: column;

      .file {
        cursor: pointer;
        &.readonly {
          cursor: default;
        }
      }
    }
    .file {
      display: flex;
      flex-direction: row;
      align-self: flex-start;
      padding-bottom: 0.5rem;

      &:hover {
        font-weight: bold;
      }
      app-config-icon[iconidentifier='delete_forever'] {
        color: var.$state-color-error;
      }
      .file-content {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: calc(80vw - 24px);
      }
      label {
        position: relative;
        width: 80vw;
        cursor: pointer;
      }
      app-config-icon {
        cursor: pointer;
      }
      input {
        opacity: 0;
      }
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
      }
    }
  }
}
