@mixin risk-return-chart-card() {
  app-risk-return-chart-card {
    .card {
      .card-body {
        div[card-body] {
          height: 100%;

          app-chart {
            display: block;
            height: 100%;

            .chart-container {
              height: 100%;
            }
          }
        }
      }
    }
  }
}
