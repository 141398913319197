@use 'src/assets/style/_variables.scss' as var;

@mixin filter-details-client() {
  .client-toggle-filter {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
  }

  .filter-details-client-container {
    .pref-container {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;

      app-filter-add {
        .add-filter-container {
          padding: 0.25rem;

          .mat-icon {
            font-size: 36px;
            width: 36px;
            height: 36px;
          }
        }
      }

      app-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-right: 1px solid var.$color-grey-5;
        margin: 0.5rem 0.75rem;

        .filter-header {
          padding-right: 0.5rem;
        }
      }

      app-filter:last-child {
        border-right: none;
      }
    }
    .sub-section {
      margin-left: 1rem;
      padding-left: 0!important;
      padding-bottom: 3px;
      border-bottom: 1px solid #3a36db;
    }
  }
}
