@use 'src/assets/style/_variables.scss' as var;

@mixin dropdown-selection-bar() {
  app-dropdown-selection-bar {
    border: 1px solid lightgray;
    border-radius: 0.25rem;
    padding: 6px;
  }
  .dropdown-selection-bar.mat-mdc-option {
    font-size: 0.85rem;

    &.create-collection-btn {
      color: var.$brand-color-primary;
    }
  }
  .dropdown-selection-bar .mat-mdc-optgroup-label {
    text-decoration: underline;
    font-size: 0.85rem;
  }

  .dropdown-selection-bar.mat-mdc-menu-item,
  .dropdown-selection-bar.mat-mdc-menu-item .mdc-list-item__primary-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    max-height: 2rem;

    &.hidden {
      display: none;
    }

    span {
      flex: 1;
    }

    span,
    mat-icon {
      font-size: 0.875rem;
      display: inline-flex;
      align-items: center;
    }

    mat-icon {
      width: 1.2rem;
      margin-right: 1px;
      margin-top: 8px;
    }

    .mat-icon.mat-icon-inline {
      margin: 0;
    }

    &.icon-text-btn {
      display: flex;
      cursor: pointer;
      margin-left: 1rem;
      color: var.$brand-color-primary;

      mat-icon {
        color: var.$brand-color-primary;
      }

      &.error-btn {
        color: var.$state-color-error;
      }

      &.disabled,
      &[disabled='true'],
      &[disabled='true']:hover {
        color: var.$color-grey-3;
        cursor: not-allowed;
      }
    }
  }
  .dropdown-selection-wrapper {
    &.mat-mdc-menu-panel {
      overflow-x: hidden;
    }
  }

  app-dropdown-selection-bar {
    display: flex;
    flex-direction: row;
    align-items: center;

    .mat-mdc--menu-trigger {
      margin-left: 1rem;
      max-height: 16px;

      > span {
        max-height: 16px;
        margin: auto;
        text-align: center;
      }
    }

    .mat-icon-button {
      height: auto;
    }

    .hidden {
      display: none;
    }
  }
}
