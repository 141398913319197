@use 'src/assets/style/_variables.scss' as var;

@mixin help-content() {
  app-help-content {
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .header-item {
      margin: 1.6rem 0 1.3rem 0rem;
      color: var.$brand-color-primary;

      &:first-child {
        margin-top: 0;
      }
    }

    .subheader-item {
      margin: 1rem 0;
      color: var.$brand-color-primary;

      &:first-child {
        margin-top: 0;
      }
    }

    .paragraph-item {
      margin: 0.25rem 0;
      text-align: justify;
      font-size: 1.2rem;
      display: inherit;

      &:first-child {
        margin-top: 0;
      }
    }

    .icon-item {
      margin: 0.25rem 0;
      text-align: justify;
      display: flex;

      &:first-child {
        margin-top: 0;
      }

      app-config-icon {
        margin-right: 0.25rem;
      }
    }

    .image-item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin: 2rem 0;

      &:first-child {
        margin-top: 0;
      }

      img {
        max-width: 100%;
      }

      .label {
        width: 100%;
        text-align: left;
        font-style: italic;
        margin-top: 0.5rem;
      }
    }
  }
}
