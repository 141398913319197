@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-action-channel-selection() {
  app-campaign-action-channel-selection {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 4px;

    .warning-message {
      padding: 10px;
      border-radius: 4px;
      background-color: var.$state-color-error;
      color: white;
    }

    .readonly-message {
      padding: 10px;
      border-radius: 4px;
      background-color: var.$state-color-warning;
    }
  }
}
