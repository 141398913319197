@use 'src/assets/style/_variables.scss' as var;

@mixin demo-questionnaire-landing-page() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  app-root {
    height: 99%;
    padding-bottom: 1%;
  }

  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  .landing-wrapper {
    height: 100%;
    background-image: url('/assets/images/pictet_background.png');
    background-repeat: no-repeat;
    font-family: Lardy Serif, Georgia, serif;
    font-size: 18px;
    font-weight: 400;
    font-feature-settings: normal;
    font-kerning: auto;
    font-optical-sizing: auto;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    color: #3e3e3e;
  }

  .landing-container {
    height: 100%;
    padding-top: 4.6rem;
    padding-left: 1rem;
    padding-right: 1.5rem;
    display: grid;
    grid-template-areas:
      "banner banner banner"
      "body body media"
      "questionnaire questionnaire questionnaire"
      "actions actions actions";
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 20% 35% 1fr 3rem;
    grid-gap: 0.5rem;
  }

  .landing-banner {
    grid-area: banner;
    width: 100%;
    display: grid;
    place-content: center;
  }

  .image {
    height: 100%;
    aspect-ratio: 16 / 9;
    background-position: center 45%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    box-shadow: 0 3px 2px #0000004d;
  }

  .landing-body {
    grid-area: body;
    text-wrap: wrap;
    line-height: 30px;
  }
  .landing-media {
    grid-area: media;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    iframe {
      width: 90%;
      height: 90%;
      border: 0;
      border-radius: 5px;
      box-shadow: 0 3px 2px #0000004d;
    }
  }
  .landing-questionnaire {
    grid-area: questionnaire;
    input {
      margin: 0.4rem;
    }
    background-color: white;
  }
  .landing-actions {
    grid-area: actions;
    display: flex;
    flex-direction: row-reverse;
    gap:0.5rem;
    background-color: white;
  }
}
