@use 'src/assets/style/_variables.scss' as var;

@mixin filter-details-operator-button() {
  app-filter-details-operator-button {
    .mat-button-toggle > button {
      height: 2rem;

      .mat-button-toggle-label-content {
        line-height: normal;
      }
    }
    .mat-button-toggle-appearance-standard {
      width: 4rem; // same size for all toggle group buttons

      &.mat-button-toggle-checked {
        background-color: var.$brand-color-primary;
        color: var.$color-white;
      }

      &.mat-button-toggle-disabled {
        opacity: 0.7;
      }

    }
  }
}
