@mixin campaign-launch-dialog() {
  app-campaign-launch-dialog {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
    flex: 1;
    padding: 2px;
    height: 99%;

    .tab-cc-framework {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex: 1;

      #add-email {
        grid-area: add;
        justify-self: start;
        align-self: flex-start;
      }

      .header {
        grid-area: header;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
      }

      app-grid {
        height: 99%;
        flex: 1;
      }
    }

    .tab-preview {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex: 1;

      .row > *{
        width: 100%;
      }

      .preview-container {
        flex: 1;
        overflow: auto;
      }
    }
  }
}
