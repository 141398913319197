@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin settings() {
  app-settings {
    .page-content-tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid var.$brand-color-secondary;
    }
    .tab-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      app-salutations,
      app-template-defaults {
        flex: 1;
      }
    }

    .page-content-header {
      display: flex;
      flex-direction: row;
      padding: 1rem;
      border-bottom: 1px solid var.$brand-color-secondary ;
    }

    .settings-users {
      display: grid;
      gap: 1.25rem;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto 1fr;
      grid-template-areas:
        'roles .'
        'users users';
      flex: 1;

      .roles-table {
        grid-area: roles;
        @include mat.m2-typography-level(typo.$config, 'body-2');
        border-collapse: collapse;

        tr {
          height: 36px;

          td,
          th {
            padding: 0.5rem 2rem;
            border-right: 1px solid var.$color-grey-5;
          }

          td:last-child,
          th:last-child {
            border-right: none;
          }
        }

        thead {
          border-bottom: 1px solid var.$color-grey-5;
        }

        tbody td {
          text-align: center;
        }
      }

      app-grid {
        grid-area: users;
      }
    }
  }
}
