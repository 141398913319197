@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-product-matching() {

  app-campaign-product-matching {
    margin: 0 0.5rem 1.5rem 0.5rem;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'header-actions header-actions header-actions'
      'available actions selected';
    width: 100%;
    height: auto;

    .header-actions-wrapper {
      grid-area: header-actions;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto auto;
      grid-template-areas:
        'minSuitability toggleTitle scoreTitle  .'
        'minSuitability toggle      scoreToggle apply';

      .minSuitability{
        width: 100%;
        padding: 0 1rem;
        align-content: start;
        grid-area: minSuitability;

        mat-form-field {
          width: 100%;
        }

        .icon-with-text {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
        }
      }

      .toggleTitle{
        padding: 0 1rem;
        margin: auto;
        grid-area: toggleTitle;
      }

      .toggle{
        padding: 0 1rem;
        margin: 0 auto auto auto;
        grid-area: toggle;
      }

      .scoreTitle {
        padding: 0 1rem;
        margin: auto;
        grid-area: scoreTitle;
      }

      .scoreToggle {
        padding: 0 1rem;
        margin: 0 auto auto auto;
        grid-area: scoreToggle;
      }

      .apply{
        padding: 0 1rem;
        margin: 0 0 auto auto;
        grid-area: apply;

        .mat-mdc-unelevated-button.mat-mdc-button-base {
          height: 1.5rem;
        }
      }
    }

    .actions {
      grid-area: actions;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 1rem;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem;
      }
      .mat-icon {
        margin-right: 0;
        margin-left: 0;
      }
    }

    .available {
      grid-area: available;
      height: 100%;
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
      .grid-basic-toolbar {
        max-height: 2.5rem;
      }
    }

    .selected {
      grid-area: selected;
      height: 100%;
    }
  }
}
