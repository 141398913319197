@mixin campaign-overview-processing() {
  app-campaign-overview-processing {
    .campaign-processing {
      display: block;

      mat-progress-bar {
        margin-top: 16px;
        margin-bottom: 16px;
      }

      div.card {
        height: calc(20% - 16px);
      }

      div.buttons {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }

      .flex-container {
        align-items: baseline;
        margin-bottom: 2rem;
      }
    }
  }
}
