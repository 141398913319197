@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-intermediary-list() {
  app-campaign-intermediary-list {
    display: flex;
    flex: 1;

    .ag-cell[col-id="hasContentOverride"] {
      padding: 0;
    }
    .grid-cell-custom-content {
      .label {
        margin-left: 0.3rem;
      }
      app-config-icon.info-icon {
        cursor: pointer;
      }
      .custom-content-cell {
        display: flex;
        flex-direction: row;
      }
    }

    .campaign-intermediary-list-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;

      .table-selection-container {
        display: flex;
        align-items: center;
        min-height: 3.125rem;
        max-height: 3.125rem;
        padding: 0 1.5rem 0 0.4rem;
        border-bottom: 1px solid var.$color-grey-6;
      }

      app-grid {
        flex: auto;
      }
    }
  }
}
