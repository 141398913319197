@use 'src/assets/style/_variables.scss' as var;

@mixin demo-filter-details-marketing-link() {
  app-demo-filter-details-marketing-link {
    padding: 1rem 1.25rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;

    app-filter-details-operator-button {
      grid-row: 1;
      grid-column: 1 / -1;
      justify-self: center;
    }

    app-filter-add {
      height: 196px;
    }

    app-tag {
      max-height: 50px;
      display: flex;
      justify-content: space-between;

      &.highlight {
        .mat-icon {
          &:hover {
            color: var.$accent-color;
          }
        }
      }

      .mat-icon {
        cursor: pointer;

        &:hover {
          color: var.$state-color-error;
        }
      }
    }

    mat-form-field {
      width: 100%;
    }
  }
}
