@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-launch-smart-notifications() {

  app-campaign-launch-smart-notifications {
    .warning-message {
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 24px;
      color: var.$color-black-1;
      background-color: var.$color-grey-5;
    }

    .smart-table {
      margin-bottom: 2rem;
    }

    .toggle-row {
      margin-left: 0.75rem;
      margin-bottom: 1.5rem;
    }

    .icon-with-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }
}
