@use 'src/assets/style/_variables.scss' as var;

@mixin my-collections() {
  app-my-collections {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 1rem;

    [col-id='del'] app-config-icon {
      color: var.$state-color-error !important;
    }

    .add-button {
      left: 1rem;
      position: absolute;
      align-self: start;
      height: 2.125rem;
    }
  }
}
