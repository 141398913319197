@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin additional-charts-card() {
  app-additional-charts-card {
    .card {
      .card-body {
        div[card-body] {
          height: 100%;

          app-chart {
            display: block;
            height: 100%;

            .chart-container {
              height: 100%;
            }
          }
        }
      }
    }

    .additional-chart {
      .card-body {
        > div,
        > div > div {
          height: 100%;
        }

        .pick-a-chart-container {
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-rows:
            calc(45% - 1rem)
            calc(45% - 1rem);
          grid-template-columns:
            calc(50% - 1rem)
            calc(50% - 1rem);
          grid-gap: 1rem;

          .pick-chart-tile {
            width: 100%;
            display: flex;
            flex-direction: column;
            color: var.$brand-color-primary;
            padding: 0px 1rem;

            &__top {
              height: 45%;
              display: flex;
              justify-content: center;
              align-items: center;
              border-bottom: 1px solid var.$color-grey-4;
            }

            &__bottom {
              display: flex;
              justify-content: space-evenly;
              margin-top: 1rem;

              &__separator {
                height: 150%;
                width: 1px;
                background-color: var.$color-grey-4;
                margin-top: -10px;
              }

              app-config-icon {
                width: 3rem;
                font-size: 3rem;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
