@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-compact-overview() {
  app-campaign-compact-overview {
    .tab-content-container {
      height: 100%;
    }

    .page-content-container {
      height: 100%;
      display: grid;
      grid-template-rows: min-content minmax(0, 1fr);
      grid-template-areas: "tabs-panel" "tabs-content";

      app-tabs-panel {
        grid-area: tabs-panel;
      }
      .tab-content-container {
        grid-area: tabs-content;
        max-height: 100%;
      }
    }

    .intermediary-list-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      app-campaign-intermediary-list {
        flex: 1;
        .campaign-intermediary-list-wrapper {
          width: 100%;
        }
      }
    }
  }
}
