@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin product-card() {
  app-product-card {
    .product {
      position: relative;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      background-color: var.$color-white;
      color: var.$color-black-1;
      border-radius: 0.5rem;
      box-shadow: var.$box-shadow;
      margin-top: 2rem;
      cursor: pointer;

      .top-wrapper {
        display: flex;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid var.$color-grey-5;
        padding: 1rem 1.5rem;
        gap: 1rem;

        app-config-icon {
          width: 2rem;
          height: 2rem;
          font-size: 2rem;
        }

        .asset-type {
          color: var.$brand-color-primary;
        }
      }

      &__product-badge {
        color: var.$color-white;
        box-shadow: var.$box-shadow;
        width: 4.5rem;
        min-width: 4.5rem;
        height: 4.5rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;

        mat-icon {
          font-size: 2rem;
        }
      }

      &__name {
        color: var.$accent-color;
      }

      .product__top {
        padding: 0.5rem;
        display: flex;
        justify-content: space-between;
      }
    }

    .product__bottom {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.5rem;

      div.performance-container {
        padding-top: 9px;
        flex: auto;
        display: flex;
        justify-content: space-evenly;
      }

      .product__region {
        position: relative;
        color: var.$brand-color-primary;

        i {
          font-style: normal;
        }

        mat-icon {
          position: relative;
          top: 2px;
          right: -4px;
          font-size: 1.2rem;
        }
      }
    }

    .product__country {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var.$accent-color;

      mat-icon {
        position: relative;
        top: -2px;
        margin-right: 4px;
      }

      i {
        font-style: normal;
      }
    }

    .product__currency {
      text-transform: uppercase;
      background: var.$accent-color;
      padding: 9px 15px;
      color: var.$color-white;
      border-radius: 3px;
      box-shadow: var.$box-shadow;
    }

  }
}
