@use 'src/assets/style/_variables.scss' as var;

@mixin demo-conversion-rate() {
  app-demo-conversion-rate {
    width: 100%;
    padding: 2px;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

    app-config-icon {
      cursor: pointer;
    }
    .value {
      flex: 1;
      text-align: center;
      position: relative;
      background: linear-gradient(to right, red, green);
      color: white;
      padding: 0.25rem;
      text-shadow: black 1px 1px 2px;
    }

    .bar {
      position: absolute;
      left: 4px;
      top: 4px;
      bottom: 4px;
      background-color: #00000044;
    }
  }

}
