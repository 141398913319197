@mixin filter-details-portfolio() {
  app-filter-details-portfolio {
    .filter-details-portfolio-container {
      display: flex;

      .filter-details-row app-filter.filter-chips {
        width: 100%;
      }
    }

    .toggles {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      width: calc(33.33% - 4rem);
      > * {
        width: calc(50% - 1rem) !important;
      }
      .client-toggle-filter {
        flex-direction: column;
      }
    }
  }
}
