@use 'src/assets/style/_variables.scss' as var;

@mixin intermediary-portfolios-popup() {
  app-intermediary-portfolios-popup {

    .intermediary-portfolios-container {
      width: 77vw;
      height: 77vh;
      min-width: 77vw;
      min-height: 77vh;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      overflow-y: hidden;
      padding-top: 2rem;

      app-grid[tableId="intermediary-portfolios-list"] {
        display: block;
        width: calc(150% - 2rem);
        height: 100%;
      }

      app-grid[tableId="intermediary-portfolios-select-list"] {
        display: block;
        width: calc(150% - 2rem);
        height: 100%;
      }

      .grid-divider {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        width: 4rem;
      }

      .collapse-button {
        position: absolute;
        top: 0;
        right: 0;
        height: 2rem;
      }

      &.collapsed {
        width: 50vw;
        height: 50vh;
        min-width: 50vw;
        min-height: 50vh;

        app-grid[tableId="intermediary-portfolios-list"] {
          width: 100%;
        }
      }
    }

    .warning-icon {
      color: var.$state-color-warning !important;
      padding-bottom: 5px;
    }

  }
}
