@mixin help() {
  .help-dialog {
    .modal-content {
      padding: 1rem !important;
    }
  }
  app-help {
    padding-top: 0 !important;

    mat-tab-group.mat-mdc-tab-group {
      max-height: 100%;
    }

    .mat-mdc-tab-body-content {
      display: flex;

      app-help-navigation {
        width: 20%;
      }

      app-help-content {
        width: 80%;
        overflow-y: auto;
      }
    }
    iframe {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: none;
    }
  }
}
