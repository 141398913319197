@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin app-message-list-dialog() {
  app-message-list-dialog {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 100%;
    grid-template-areas: "list content";
    grid-gap: 0.5rem;
    height: 98%;

    app-grid {
      grid-area: list;
    }
    .app-message-card {
      grid-area: content;
    }
    .app-message-content {
      padding: 1rem;
      min-width: 20rem;
      min-height: 20rem;
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
}
