@use 'src/assets/style/_variables.scss' as var;

@mixin callback() {
  app-callback {
    .auth-container {
      position: fixed;
      top: 25%;
      left: 50%;
      transform: translateX(-50%);

      div[card-body] {
        width: 500px;
      }

      .btn-container {
        display: flex;
        gap: 1rem;
      }

      .toggle {
        display: flex;
        justify-content: center;
        cursor: pointer;
        font-size: 2rem;
        color: var.$brand-color-primary;
      }
    }
  }
}
