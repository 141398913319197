
@mixin monitoring-charts-card() {
  app-monitoring-charts-card {
    .chart-pages {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }
    .chart-page {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      gap: 1rem;
      width: 100%;
      height: 100%;
    }
    .chart-graph {
      display: flex;
      justify-content: stretch;
      align-items: stretch;
      flex: 1;
    }
    swiper-container {
      width: 100%;

      &::part(bullet),
      &::part(bullet-active){
        width: 16px;
        height: 16px;
      }
    }
  }
}
