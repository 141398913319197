@use 'src/assets/style/_variables.scss' as var;

@mixin content-form-fields() {
  .modal-form {
    textarea {
      height: unset;
    }
    // add the required asterisk to the label
    .required {
      mat-label::after {
        margin-left: 1px;
        margin-right: 0px;
        content: "*";
      }
    }
    // update label text color on error and remove padding bottom
    .error {
      padding-bottom: 0;
      mat-label, mat-error {
        color: var(--mat-form-field-error-text-color);
      }
      mat-error {
        padding-left: 1rem;
        font-family: var(--mat-form-field-subscript-text-font);
        line-height: var(--mat-form-field-subscript-text-line-height);
        font-size: var(--mat-form-field-subscript-text-size);
        letter-spacing: var(--mat-form-field-subscript-text-tracking);
        font-weight: var(--mat-form-field-subscript-text-weight);
      }
      .fr-box.fr-basic {
        .fr-top {
          border-top-color: var(--mat-form-field-error-text-color);
          border-left-color: var(--mat-form-field-error-text-color);
          border-right-color: var(--mat-form-field-error-text-color);
        }
        .fr-wrapper {
          border-left-color: var(--mat-form-field-error-text-color);
          border-right-color: var(--mat-form-field-error-text-color);
        }
        .fr-second-toolbar {
          border-left-color: var(--mat-form-field-error-text-color);
          border-right-color: var(--mat-form-field-error-text-color);
          border-bottom-color: var(--mat-form-field-error-text-color);
        }
      }
    }
  }
  app-content-form {
    .warning-message {
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 24px;
      color: var.$color-black-1;
      background-color: var.$color-grey-5;
    }
  }
  .file-status {
    button,
    app-config-icon {
      width: 2rem;
      height: 2rem;
    }
    button {
      margin-right: 1rem;
    }
    app-config-icon {
      font-size: 2rem;
      margin-bottom: -0.5rem;
    }
  }
  app-content-form-fields {
    padding-top: 8px;
    padding-bottom: 4px;
    display: block;
  }
  .rich-text-section {
    flex-direction: column;
    flex-wrap: unset;
    padding-bottom: 22px;
    word-break: break-word;

    mat-label, mat-error {
      font-size: 0.875rem;
    }

    mat-label {
      color: var(--mdc-filled-text-field-label-text-color);
    }

    &.single-line {
      text-wrap: nowrap;

      .fr-wrapper {
        display: flex;
        padding: 20px;

        &.show-placeholder .fr-placeholder {
          padding: 20px !important;
        }

        .fr-element {
          min-width: 0;
          width: 180px; // needed to prevent element to expand more than the parent; same concept as mat-form-field
          flex: auto;
          scrollbar-width : none;
          padding: unset;
          min-height: unset;
        }
      }
    }
  }
}
