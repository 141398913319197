@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-compact-targeted-clients() {
  app-campaign-compact-targeted-clients {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1rem;
    padding-bottom: 1rem;

    app-filter-active-tags {
      app-card {
        display: flex;
        height: 100%;
        .card {
          width: 100%;
        }
      }
    }
  }
}
