@use 'src/assets/style/_variables.scss' as var;

@mixin monitoring-campaign-overview() {
  app-monitoring-campaign-overview {
    width: 100%;
    height: 100%;

    .h4 {
      display: flex;
    }

    mat-button-toggle-group {
      span {
        font-size: 12px;
        line-height: 32px!important;
      }
    }
    .sub-header-container {
      border-bottom: none;
    }
    .card {
      .card-header-container {
        padding: 0.5rem 1.5rem;
        min-height: 73px;
        .card-header {
          min-height: 4rem;
        }
        .card-actions {
          align-self: flex-end;
        }
      }
      .card-body {
        padding-bottom: 0;
      }
    }
    .overview-container {
      display: flex;
      height: 100%;
      .card-body {
        padding: 1rem;
      }
    }

    .campaign-container {
      display: grid;
      padding: 0 1rem 1rem 0;
      grid-template-columns: calc(20% - 0.5rem) 20% calc(60% - 0.5rem);
      grid-template-rows: calc(40% - 0.5rem) calc(60% - 0.5rem);
      grid-template-areas:
      "values       details      details"
      "transactions transactions charts";
      gap: 1rem;

      .campaign-values {
        grid-area: values;
        display: grid;
        grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
        grid-template-rows: calc(50% - 0.5rem) calc(50% - 0.5rem);
        gap: 1rem;
      }
      .card-overview {
        grid-area: details;
        width: 100%;

        .card-body {
          padding-bottom: 0;
        }
      }
      .card-movements {
        grid-area: transactions;

        div[card-body] {
          display: flex;
          flex-direction: column;
        }

        .tab-group {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }

        .card-body {
          padding-bottom: 0;
        }
      }
      .card-charts {
        grid-area: charts;
      }
    } /* .campaign-container  */
  } /* app-monitoring-campaign-overview */
}
