@use 'src/assets/style/_variables.scss' as var;

@mixin content-preview() {
  .custom-content-dialog {
    height: 90vh;
    app-modal,
    .modal-content {
      height: 100%;
    }
  }
  app-content-preview {
    .preview-container {
      overflow: auto;
    }

    .row {
      gap: 10px;
    }

    .row > * {
      flex-grow: 1;
    }
  }
}
