@use 'src/assets/style/_variables.scss' as var;

@mixin grid-filter-item-text() {
  app-grid-filter-item-text {
    .grid-filter-item {
      .grid-filter-item-content {
        form {
          display: flex;
          flex-direction: column;

          .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
          .mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
          .mat-mdc-standard-chip .mat-mdc-chip-action-label {
            overflow: hidden;
          }
        }
      }
    }
  }
}
