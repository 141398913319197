@use 'src/assets/style/_variables.scss' as var;

@mixin filter-active-tags() {
  app-filter-active-tags {
    app-card.no-header-action {
      .card .card-header {
        cursor: default;
      }
    }

    .card {
      height: unset;
    }

    .active-tags-card-body {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      .filter-separator {
        height: 1px;
        width: 100%;
        background-color: var.$color-grey-5;
      }
    }

    .user-filters {
      display: flex;
      align-items: center;
      gap: 1.25rem;

      .selected-filter-action-container {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        top: -10px;

        .icon-actions {
          display: flex;
          gap: 0.5rem;

          app-config-icon {
            cursor: pointer;
          }
        }

        .save-actions {
          display: flex;
          gap: 1.25rem;
        }
      }

      app-config-icon[iconIdentifier='delete'] {
        color: var.$state-color-error;
      }
    }

    .active-tags-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      &.no-edit {
        app-tag {
          cursor: default;
        }
      }
    }
  }
}
