@mixin external-link-list() {
  app-external-link-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0.5rem;
    max-height: 75vh;
    overflow-y: auto;

    .link-item {
      border-bottom: 1px solid var.$color-grey-3;
      margin-top: 0.5rem;
      width: 100%;
      padding: 1rem 0.1rem;
      display: flex;
      flex-direction: column;
    }
    .link-item:first-child {
      padding-top: 0;
    }
    .link-item:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    .link-description {
      margin-top: 0.7rem;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }
}
