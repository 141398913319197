@mixin campaign-suitability-details() {
  app-campaign-suitability-details {
    --min-height: 25rem;
    display: flex;
    flex-direction: column;
    min-height: var(--min-height);
    padding-bottom: 1rem;

    app-grid {
      display: flex;
      flex-direction: column;
    }

    .suitability-header {
      margin-bottom: 10px;

      .status {
        display: flex;
        align-items: self-end;

        app-config-icon {
          width: 1.5rem;
          height: 1.5rem;
          margin-left: 4px;

          .mat-icon {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
