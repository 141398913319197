@use 'src/assets/style/_variables.scss' as var;

@mixin story-overview-details() {
  app-story-overview-details {
    min-width: 45rem;

    app-config-icon.details-hot-story{
      cursor: default !important;
      color: var.$color-black-1 !important;
    }

    .story-title > span {
      width: 100%;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
    }

    .story-detail-item {
      padding-top: 0.625rem;
      overflow-y: hidden;

      app-config-icon {
        margin-right: 6px;
        vertical-align: sub;
      }
    }

    .card-body > div {
      display: flex;
      flex-direction: column;

      .content-wrapper {
        display: grid;
        grid-template-columns: 60% 1fr;
        grid-template-rows: 1fr auto;
        grid-template-areas: "details cover-image" "tags tags";

        .detail-items-wrapper {
          grid-area: details;
        }
        app-story-details-image {
          grid-area: cover-image;
        }
        .story-detail-item {
          grid-area: tags;
        }
      }

      .actions-wrapper {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 24px;

        button + button {
          margin-right: 12px;
        }
      }

      .detail-items-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        min-width: 48%;
      }

      .detail-items {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }

      .detail-item.body {
        .label {
          min-width: 11rem;
          max-width: none;
        }
        .value {
          max-width: 20rem;
        }
        .value > span {
          max-width: 14rem;
        }
      }

      .detail-items {
        flex-grow: 1;
      }

    }
  }
}
