@use 'src/assets/style/_variables.scss' as var;

@mixin filter-configs() {
  app-filter-configs {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    .disabled {
      cursor: not-allowed;
    }

    .container {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 1rem;
    }

    .hub-header {
      display: flex;
      padding: 1rem;
    }

    .filter-configurations {
      display: flex;
      flex: 1;
      flex-direction: row;
      overflow: auto;
      gap: 1rem;
      padding: 1rem;
      border: 1px solid var.$color-grey-6;
      border-radius: 0.5rem;
    }

    .child-item {
      padding-left: 1rem;
    }

    .mat-mdc-slide-toggle {
      padding-top: 0.2rem;
    }
  }
}
