@mixin campaign-info() {
  app-campaign-info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .info {
      overflow: auto;
      padding: 0.5rem;
      white-space: pre-wrap;
    }
  }
}
