@use 'src/assets/style/mixins.scss' as mixin;

@mixin filter-details-region() {
  app-filter-details-region {
    padding: 1rem 1.25rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;

    app-filter-details-operator-button {
      grid-row: 1;
      grid-column: 1 / -1;
      justify-self: center;
    }

    app-filter-add,
    app-filter-range-weight {
      height: 196px;
    }
  }
}
