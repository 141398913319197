@use 'src/assets/style/_variables.scss' as var;

@mixin distributor-summary() {
  app-distributor-summary {
    padding: 1rem 3.25rem 1rem 3.25rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .summary-header {
      display: flex;
      flex-direction: row;
      margin: 0.75rem 0;
      align-items: baseline;
      column-gap: 0.25rem;
      min-width: max-content; // prevent text wrapping
    }

    .summary-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid var.$color-grey-3;
      gap: 0.5rem;
      padding: 1rem;

      &:last-child {
        border-bottom: none;
      }

      .summary-icon {
        padding: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 4px;
        color: white;
        width: 3rem;
        height: 3rem;

        &.received {
          background-color: #ade4e4;
        }
        &.viewed {
          background-color: #ee98ba;
        }
        &.turnover {
          background-color: #ffb47e;
        }
        &.executed {
          background-color: #95b3f8;
        }
      }

      .summary-value {
        font-weight: bold;
        font-size: 1.3rem;
      }
      .summary-details {
        display: flex;
        flex-direction: row;

        .label {
          min-width: 6rem;
        }
        .value {
          flex: 1;
        }
      }
    }
  }
}
