@use 'src/assets/style/_variables.scss' as var;

@mixin filter-details-org-positions() {
  app-filter-details-org-positions {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // modal-height - header - padding - tabs - footer
    min-height: calc(90vh - 4.375rem - 1rem - 3rem - 4.9rem);

    .filter-details-org-positions-container {
      display: flex;
      height: 100%;
    }

    .filter-org-positions-details {
      display: flex;
      flex: 1;
      gap: 1rem;

      > app-grid .ag-cell .ag-cell-wrapper .ag-cell-value {
        white-space: nowrap !important;
        text-overflow: ellipsis;
      }

      .flex-1 {
        flex: 1;
      }

      .flex-2 {
        flex: 2;

        .grid-filter-wrapper {
          padding-right: 0;
        }
      }
      .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .ag-root-wrapper-body {
        border: unset;
      }
    }
  }
}
