@mixin filter-details-position() {
  app-filter-details-position {
    .filter-details-position-container {
      .filter-details-row {
        app-filter.filter-chips {
          width: 100%;
        }
      }
    }
  }
}
