@use 'src/assets/style/_variables.scss' as var;

@mixin employee-overview() {
  .employee-overview-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    gap: 1.25rem;
    flex: 1;

    > *:first-child app-card:first-child {
      .card {
        border-top-left-radius: 0;
      }
    }
    .detail-items {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      .detail-item .value {
        flex: 1; // use the available space
        > span {
          max-width: 40rem;
        }
      }
    }

    app-employee-overview-actions,
    app-employee-overview-campaigns {
      grid-column: span 2;
      grid-row: span 2;
    }
  }
}

