@mixin filter-details-asset() {
  app-filter-details-asset {
    max-width: calc(90vw - 4rem);

    .filter-assets {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      gap: 2rem;
    }
    .filter-asset-column {
      flex: 1;
      flex-direction: column;
      align-items: stretch;
    }
    .filter-asset-title {
      font-size: 1.4rem;
      align-self: center;
    }
    .filter-search {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 1rem;
      column-gap: 1rem;
      border-bottom: 1px solid grey;

      .spacer {
        flex: 1;
      }
      button.add-product {
        height: 2.5rem;
        margin-bottom: 0.25rem;
      }

      app-filter {
        width: 50%;

        mat-form-field {
          width: 100%;
        }
      }

      .mat-form-field-prefix {
        display: flex;
      }
    }

    app-filter-asset {
      app-filter {
        .filter-content {
          padding: 0;
        }
      }
    }

    .filter-list {
      grid-area: filters;
      padding: 1rem 1.25rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;

      app-filter-range-weight {
        height: 196px;
      }
    }

    app-tag.primary,
    .filter-content {
      max-width: calc(21vw - 1rem);
    }
  }
}
