@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-action-documents() {
  app-campaign-action-documents {
    app-grid {
      height: 100%;
      min-height: 12.5rem;
      padding-bottom: 0.5rem;
    }
  }
}
