@mixin filter-details-advanced() {
  .filter-details-advanced-container {
    #blocklyDiv {
      height: 50vh;
    }

    .filter-details-row {
      app-filter.position-asset-chips {
        width: 100%;
      }
    }
  }
}
