@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin monitoring-campaign() {
  app-monitoring-campaign {

    .tabs-panel {
      display: flex;
      gap: 5rem;
      height: 60px;
      padding: 0 1.5rem;
      background-color: var.$color-grey-6;
      color: var.$color-grey-3;

      app-tab {
        height: 100%;
      }
    }

    .catalogue-container {
      display: flex;
      flex-direction: column;
      justify-content: normal;
      align-items: stretch;
      position: relative;

      .card-collapsible {
        align-items: center;
        cursor: pointer;
      }
      dl.rate,
      dl.avg {
        font-weight: bold;

        dd {
          font-weight: normal;
        }
      }
    }
    app-progress-update {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1rem;
      background-color: white;
    }
    .chart-container {
      flex: 1;
    }
    .list-container {
      flex: 1;
    }

    .list-container .card-header,
    .chart-container .card-header{
      cursor: pointer;
      align-items: center;
    }
    .card-header,
    .card-collapsible {
      display: flex;
      flex-direction: row;
    }
    .card-collapsible {
      flex: 1;
    }

    .chart-container {
      padding: 1rem;

      &.collapsed {
        flex: unset;
        padding-bottom: 0;
        .card-body {
          display: none;
        }
      }

      dl {
        margin-block-end: 0;
      }

      .chart-body {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr;
        grid-template-areas:
          "upper"
          "lower";
        grid-gap: 1rem;
        width: 100%;
        height: 100%;

        .chart-row {
          display: flex;
          flex-direction: row;
          max-width: 100%;
          padding-top: 2px;
          padding-bottom: 2px;
        }
        .chart-row.upper {
          grid-area: upper;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-gap: 1rem;

          .chart-graph {
            box-shadow: var.$box-shadow;
            border-radius: 4px;
            padding: 2px;

            position: relative;
            display: grid;
            grid-template-columns: auto 1fr auto;
            grid-template-rows: auto 1fr;
            grid-template-areas:
              "rate  .     avg"
              "graph graph graph";

            dl.rate {
              grid-area: rate;
              dd {
                text-align: left;
              }
            }
            dl.avg {
              grid-area: avg;
              text-align: right;
            }
            app-monitoring-chart {
              grid-area: graph;
            }
            dl {
              margin-top: 0;
              padding: 0.5rem;
              dt {
                font-weight: bold;
              }
              dd {
                font-weight: normal;
                text-align: right;
              }
            }
          }

        }
        .chart-row.lower {
          grid-area: lower;
          .chart-page app-card {
            padding: 2px;
            box-sizing: border-box;
          }
        }
        .chart-row > * {
          flex: 1;
        }
      }
      .chart-graph {
        min-height: 90%;

        dd {
          font-weight: bold;
          text-align: left;
          margin: 0;
        }

        &.trade-volume {
          border: none;
          box-shadow: none;
          padding: 0;
          &> .card > .card-body {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: auto auto 1fr auto;
            grid-template-areas:
            "graph graph graph graph total-volume"
            "graph graph graph graph total-trades"
            "graph graph graph graph ."
            "buy-volume sell-volume buy-trades sell-trades .";
          }

          app-monitoring-chart {
            grid-area: graph;
          }
          dd {
            color: black;
          }
          .total-volume {
            grid-area: total-volume;
            font-weight: normal;
            color: var.$color-grey-2;
            dd {
              font-weight: bold;
              padding-bottom: 2rem;
            }
          }
          .total-trades {
            grid-area: total-trades;
            font-weight: normal;
            dd {
              font-weight: bold;
            }
          }
          .buy-volume {
            grid-area: buy-volume;
            color: var.$color-grey-3;
          }
          .sell-volume {
            grid-area: sell-volume;
            border-right: 1px solid grey;
            padding-right: 1rem;
            color: var.$color-grey-3;
          }
          .buy-trades {
            grid-area: buy-trades;
            padding-left: 1rem;
            color: var.$color-grey-3;
          }
          .sell-trades {
            grid-area: sell-trades;
            color: var.$color-grey-3;
          }
        } /* trade-volume */

        &.centralized-campaigns {
          border: none;
          box-shadow: none;
          padding: 0;
          &> .card > .card-body {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto 1fr;
            grid-template-areas:
            "graph details"
            "graph .";
            padding-bottom: 0;
          }

          app-monitoring-chart {
            grid-area: graph;
          }
          dl {
            grid-area: details;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            max-width: 200px;
            dt {
              font-weight: bold;
              color: var.$color-grey-2;
            }
            dd.total {
              font-weight: bold;
            }
            dd {
              font-weight: normal;
            }
            dd.message {
              color: var.$color-grey-3;
            }
          }
        } /* centralized-campaigns */
      }
      swiper-container {
        width: 100%;
        padding-bottom: 1rem;

        &::part(container){
          position: initial;
        }

        &::part(bullet),
        &::part(bullet-active){
          width: 16px;
          height: 16px;
        }
        &::part(pagination) {
          position: absolute;
          bottom: -1.5rem;
        }
      }

      .chart-page {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        gap: 1rem;
        width: 100%;
        height: 100%;

        .chart-graph {
          width: 100%;
          flex: 1;
        }
      }

    } /* chart-container */

    .list-container {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;

      &.collapsed {
        flex: unset;
        padding: 3rem 1rem 1rem;

        .card-body {
          display: none;
        }
      }
    } /* list-container */

    .grids-card {
      flex: 1;
      display: flex;
      flex-direction: column;

      .tab-group {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 0.5px solid var.$color-grey-4;
      }

      .mat-button-toggle-group {
        height: 2.5rem;

        .mat-button-toggle-button {
          height: 2.5rem;
        }
        .mat-button-toggle-label-content {
          line-height: unset;
        }
      }

      app-grid {
        flex: auto;
      }
    }
  }

}
