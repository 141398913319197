@use 'src/assets/style/_variables.scss' as var;

@mixin filter-add() {
  app-filter-add {
    .add-filter-container {
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      height: 100%;
      border: 3px var.$color-grey-6 dashed;
      border-radius: 0.5rem;

      app-config-icon {
        font-size: 6rem;
        width: 6rem;
        height: 6rem;
        color: var.$brand-color-primary;
        cursor: pointer;
      }
    }

    app-config-icon {
      width: auto;
    }
  }
}
