@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-overview() {
  app-campaign-overview {
    .tab-content-container {
      height: 100%;

      &.tab-content-grid-only {
        display: flex;
        flex-direction: column;

        app-portfolio-list,
        app-campaign-portfolio-list,
        app-campaign-intermediary-list,
        app-campaign-send,
        app-campaign-compact-send {
          flex: 1;
        }
      }
    }
    .overview-container-parent {
      display: block;
      overflow: auto;
      height: 100%;
    }
    .content-container {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 1rem;
      padding: 1rem 1rem;
    }

    .overview-details-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      height: 100%;
      gap: 1rem;
      padding: 1rem 1rem;

      .details-column {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto 1fr;
        height: 100%;
        width: 100%;
        gap: 1rem;
      }
      .charts-column {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 45vh 45vh;
        height: 100%;
        width: 100%;
        gap: 1rem;
      }

      app-campaign-overview-details {
        app-story-details-image {
          padding-bottom: 3rem;
        }
      }
      .stats-filters-wrapper {
        padding-bottom: 1rem;
      }
      app-risk-return-chart-card,
      app-campaign-suitability-kpi-chart-card {
      }
      app-additional-charts-card,
      app-campaign-actions-kpi-chart-card {
        padding-bottom: 1rem;
      }
    }

    .overview-container, .overview-details-container {
      app-statistics {
        margin-bottom: 1rem;
      }

      .filters-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
      }

      .filters-container {
        grid-column: span 2;
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }
    }

    .product-list {
      grid-column: span 2;
    }

    .campaign-contents {
      grid-column: span 4;
    }

    .flex-container {
      display: flex;
      gap: 2rem;
    }
    .intermediary-list-container {
      flex: 1;
      display: flex;
      flex-direction: column;

      app-intermediary-list {
        flex: 1;
      }
    }
  }

  .card-body {
    min-height: 0;
    max-height: 100%;
  }

  .mat-mdc-menu-item-button .mat-mdc-menu-item-text {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
  app-campaign-overview-toolbar {
    &>.basic-flex {
      display: flex;
      flex-direction: row;
      align-items: center;

      .primary-button {
        padding: 0.75rem;
      }
    }
    .mdc-button__label app-config-icon {
      color: white !important;
    }
    .mat-mdc-menu-trigger app-config-icon {
      color: inherit;
    }
  }
  .tab-intermediaries {
    align-items: stretch;
    .sub-header-container .sub-header-actions {
      flex: 1;
    }
  }
  app-sub-header.intermediary-actions {
    .sub-header-actions {
      flex: 1;
    }
  }
}
