@use 'src/assets/style/_variables.scss' as var;

@mixin story-details-image() {
  .story-details-file {
    display: flex;
    flex-direction: column;

    .preview-container {
      flex: 0 0 40%;
      box-shadow: var.$box-shadow;

      .image {
        height: 100%;
        width: 100%;
        aspect-ratio: 16 / 9;
        background-position: center 45%;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .mat-mdc-icon-button {
        background-color: var.$color-grey-6;
      }
    }

    .file {
      input {
        display: none;
      }
    }
  }
}
