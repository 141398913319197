@use 'src/assets/style/_variables.scss' as var;

@mixin story-details-form() {
  .story-detail-dialog {
    app-modal,
    .modal-content {
      height: 100%;
    }
  }
  app-details-form {
    form {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .mat-button-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #split-panel {
      flex: 1;
      min-height: 15rem;
      overflow-x: clip;
      /*Sets the textarea to the same height as it's container*/
      .mat-mdc-form-field .mdc-text-field .mat-mdc-form-field-flex {
        height: 100%;
        .mat-mdc-form-field-infix {
          height: 100%;
          textarea {
            height: 100%;
          }
        }
      }
    }

    /*Probably used for tags*/
    .action-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
    }
  }
}
